import React, { useRef, useState, useCallback, memo, useEffect } from 'react'
import moment from 'moment'
import axios from 'axios'
import { API_URL } from '../config/config'
import { NotificationManager } from 'react-notifications'

export const EndSessionTimerContext = React.createContext()

export const EndSessionTimerProvider = ({ children }) => {
    const [showTimer, setShowTimer] = useState(false)
    const [timerValue, setTimerValue] = useState(null)
    const intervalRef = useRef(null)
    const componentRef = useRef(null)

    const disableTimer = useCallback(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current)
        }
        setShowTimer(false)
        setTimerValue(null)
    }, [])

    const startTimer = (ms) => {
        setShowTimer(true)
        setTimerValue(moment(ms))

        if (intervalRef.current) {
            clearInterval(intervalRef.current)
        }

        intervalRef.current = setInterval(() => {
            setTimerValue(prev => {
                if (!prev || prev.valueOf() > 180000) {
                    disableTimer()
                    return prev
                }
                return moment(prev).subtract(1, 'second')
            })
        }, 1000)

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
            }
        }
    }

    useEffect(() => {
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
            }
        }
    }, [])

    const handleSubmit = useCallback(async (evt) => {
        evt.preventDefault()
        if (showTimer === null) return

        setShowTimer(null)

        try {
            await axios.get(`${API_URL}/api/auth/retakeToken`)
            const newExpirationDate = new Date(new Date().getTime() + 1000 * 60 * 60)
            localStorage.setItem('expirationDate', newExpirationDate.toISOString())
        } catch (error) {
            localStorage.setItem("lastRetakeToken", Date.now().toString())
            console.error(error)
            NotificationManager.error(
                "Twoja sesja nie została przedłuźona",
                "zostaniesz wylogowany za 5 minut"
            )
        } finally {
            setShowTimer(false)
            disableTimer()
        }
    }, [showTimer, disableTimer])

    const Timer = memo(() => (
        showTimer ? (
            <div
                className="d-flex justify-content-between align-items-center"
                ref={componentRef}
                style={{backgroundColor: "#C5D8FF", borderRadius: "5px"}}
            >
                <div className={"ml-2"} style={{fontWeight: "bold"}}>
                    {timerValue && moment().subtract(timerValue)  > 0
                        ? timerValue.format("mm:ss")
                        : "wylogowanie..."}
                </div>
                <div>
                    <button
                        onClick={handleSubmit}
                        className="btn ml-1 btn-primary"
                    >
                        Przedłuż
                    </button>
                </div>
            </div>
        ) : null
    ))

    Timer.displayName = 'Timer'

    const contextValue = {
        StartTimer: startTimer,
        EndTimer: disableTimer,
        Timer,
        TimerIsShow: showTimer
    }

    return (
        <EndSessionTimerContext.Provider value={contextValue}>
            {children}
        </EndSessionTimerContext.Provider>
    )
}