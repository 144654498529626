import React, { useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Title,
  Legend,
} from "chart.js";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Title, Legend);

export function ValueSalesChart({
  dataset1,
  dataset2,
  dataset3,
  firstBarKey,
  secondBarKey,
  countDataset1,
  countDataset2,
  countDataset3,
}) {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.update();
    }
  }, [
    dataset1,
    dataset2,
    dataset3,
    firstBarKey,
    secondBarKey,
    countDataset1,
    countDataset2,
    countDataset3,
  ]);

  const barColors = ["#C5D8FF", "#0140BE"];

  const datasets = [];
  if (secondBarKey) {
    datasets.push({
      label: secondBarKey || null,
      data: dataset2,
      backgroundColor: "#c5d8ff81",
      borderColor: barColors[0],
      countData: countDataset2,
      borderDash: [5, 5],
      // fill: true
    });
  }
  if (firstBarKey) {
    datasets.push({
      label: firstBarKey || null,
      data: dataset1,
      countData: countDataset1,
      // fill: true,
      borderColor: barColors[1],
      backgroundColor: "#0140be80",
    });
  }
  if (dataset3) {
    datasets.push({
      data: dataset3,
      countData: countDataset3,
      // fill: true,
      backgroundColor: "red",
      backgroundColor: "#FF7A00",
    });
  }
  const labels = Array.from({ length: 24 }, (_, hour) => `${hour}:00`);
  const chartData = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          generateLabels(chart) {
            return chart.data?.datasets?.map((dataset, i) => {
              let dateLabel = dataset?.label?.replace(/^\b(\d{4}-\d{2}-\d{2})\b.*/, '$1')
              if (dataset.backgroundColor === "#FF7A00") {
                dateLabel = "Bieżąca godzina"
              } else {
                dateLabel = dataset?.label?.replace(/^\b(\d{4}-\d{2}-\d{2})\b.*/, '$1')
              }
                
              return {
                text: dateLabel,
                fillStyle: dataset.backgroundColor,
                strokeStyle: dataset.borderColor,
                lineWidth: 1,
                hidden: !chart.isDatasetVisible(i),
                datasetIndex: i
              };
            });
          },
        }
      },
      title: {
        display: true,
        text: "Sprzedaż bieżąca",
      },
      tooltip: {
        mode: "index",
        intersect: false,
        enabled: true,
        callbacks: {
          label: (ctx) => {
            const dataset = ctx.chart.data.datasets[ctx.datasetIndex];
            const price = ctx.raw;
            let label
            if (ctx.dataset.label) 
              label = ctx.dataset.label.replace(/^\b(\d{4}-\d{2}-\d{2})\b.*/, '$1')
            const plnFormat = new Intl.NumberFormat('pl-PL', {
              style: 'currency',
              currency: 'PLN',
            })

            if (dataset.backgroundColor === "#FF7A00") {
              label = "Bieżąca godzina"
            }
            const countData = dataset.countData;

            if (!countData) 
              return `${label}: ${price} zł`;

            const count = countData[ctx.dataIndex] || 0;
            return `${label}: ${plnFormat.format(price)} (${count} szt.)`;
          },
        },
      },
    },
  };

  return <Line ref={chartRef} data={chartData} options={options} />;
}