import React, { useState, useEffect, Fragment, useContext } from 'react'
import { LangContext } from '../../context/lang-context'
import useFetch from '../../hooks/fetchMSSQL-hook'
import TextInput from '../FormElements/TextInput';
import moment from 'moment';
import FormSkel from '../Modals/FormSkel'
import Tags from './components/Tags'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import validateDateTime from '../../util/validateDateTime';

export default  ({ filter, setFilter, columns, resetPage, resetFilter, search, toggleFilter, enableMachineTags, setIsFlatTable, isFlatTable }) => {
  const { TRL_Pack } = useContext(LangContext)

  const [terminals, setTerminals] = useState([])
  const [machines, setMachines] = useState([])
  const [terminalTypes, setTerminalTypes] = useState([])
  const [paymentTypes, setPaymentTypes] = useState([])
  const { fetchMssqlApi } = useFetch()

  useEffect(() => {
    getTerminals()
    getMachines()
    getTerminalTypes()
    getPaymentTypes()
  }, [])

  const getTerminals = () => {
    fetchMssqlApi('terminal-list', {
      method: 'POST',
      hideNotification: true,
      data: {
      }
    }, terminals => setTerminals(terminals))
  }

  const getMachines = () => {
    fetchMssqlApi('machine-list', {
      method: 'POST',
      hideNotification: true,
      data: {
      }
    }, machines => setMachines(machines))
  }

  const getTerminalTypes = () => {
    fetchMssqlApi('terminal-type-list', {
      method: 'POST',
      hideNotification: true,
      data: {
      }
    }, terminalTypes => setTerminalTypes(terminalTypes))
  }

  const getPaymentTypes = () => {
    fetchMssqlApi('payment-type-list', {
      method: 'POST',
      hideNotification: true,
      data: {
      }
    }, paymentTypes => setPaymentTypes(paymentTypes))
  }


  const handleChange = evt => {
    const { name, value, type, checked } = evt.target

    // resetPage()

    if (type === 'checkbox') setFilter(prev => ({ ...prev, [name]: checked }))

    else setFilter(prev => ({ ...prev, [name]: value }))
    if (resetPage) resetPage()
  }

  const handleChangeSelectbar = evt => {
    const { name, value } = evt.target

    //console.log(filter);
    //console.log("handleChangeSelectbar");

    // resetPage()

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.name === name) col.selectbar = value
        if (col.name === name) col.searchbar = value

        return col
      })
    }))
  }

  const handleChangeDateTimeRangeToday = evt => {
    const { name, value, text } = evt.target

    // resetPage()

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.type === "datetimeRange") {
          col.dateTimeRangeFrom = moment().format('YYYY-MM-DDT00:00:00');
          col.dateTimeRangeTo = moment().format('YYYY-MM-DDT23:59:59');
        }
        return col
      })
    }))
  }
  const handleChangeDateTimeRangePreviousMonth = evt => {

    // resetPage()

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.type === "datetimeRange") {
          col.dateTimeRangeFrom = moment(moment().add(-1, 'M')).startOf("month").format('YYYY-MM-DDT00:00:00');
          col.dateTimeRangeTo = moment(moment().add(-1, 'M')).endOf("month").format('YYYY-MM-DDT23:59:59');
        }
        return col
      })
    }))
  }
  const handleChangeDateTimeRangePreviousWeek = evt => {

    // resetPage()

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.type === "datetimeRange") {
          col.dateTimeRangeFrom = moment(moment().add(-1, 'w')).startOf('week').format('YYYY-MM-DDT00:00:00');
          col.dateTimeRangeTo = moment(moment().add(-1, 'w')).endOf('week').format('YYYY-MM-DDT23:59:59');
        }
        return col
      })
    }))
  }
  const handleChangeDateTimeRangeLastWeek = evt => {
    const { name, value, text } = evt.target

    // resetPage()

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.type === "datetimeRange") {
          col.dateTimeRangeFrom = moment().add(-7, 'd').format('YYYY-MM-DDT00:00:00');
          col.dateTimeRangeTo = moment().format('YYYY-MM-DDT23:59:59');
        }
        return col
      })
    }))
  }
  const handleChangeDateTimeRangeLastMonth = evt => {
    const { name, value, text } = evt.target

    // resetPage()

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.type === "datetimeRange") {
          col.dateTimeRangeFrom = moment().add(-1, 'M').format('YYYY-MM-DDT00:00:00');
          col.dateTimeRangeTo = moment().format('YYYY-MM-DDT23:59:59');
        }
        return col
      })
    }))
  }

  const handleChangeDateTimeRangeFrom = evt => {
    const { value } = evt.target

    // resetPage()

    //console.log(value)
    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.type === "datetimeRange") {
          if (moment(value).isValid())
            col.dateTimeRangeFrom = moment(value).format('YYYY-MM-DDTHH:mm:ss')
          // col.dateTimeRangeFrom = moment(valueAsNumber).format('YYYY-MM-DDTHH:mm:ss');
        }
        return col
      })
    }))
  }

  const handleChangeDateTimeRangeTo = evt => {
    const { value } = evt.target
    // console.log(valueAsNumber);

    // resetPage()
    //console.log(value)
    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.type === "datetimeRange") {
          //   console.log(col);
          if (moment(value).isValid())
            col.dateTimeRangeTo = moment(value).format('YYYY-MM-DDTHH:mm:ss');
          // col.dateTimeRangeTo = moment(valueAsNumber).format('YYYY-MM-DDTHH:mm:ss');
        }
        return col
      })
    }))
  }
  const handleChangeDateTimeRangeWhenever = evt => {
    const { name, value, text } = evt.target

    // resetPage()

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.type === "datetimeRange") {
          col.dateTimeRangeFrom = moment().add(-20, 'y').format('YYYY-MM-DDT00:00:00');
          col.dateTimeRangeTo = moment().format('YYYY-MM-DDT23:59:59');
        }
        return col
      })
    }))
  }

  const handleChangeProductNo = evt => {
    const { name, value } = evt.target

    // resetPage()

    let productNo = null;

    if (value !== '' && value !== undefined)
      productNo = value;

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.name === name) col.ProductNo = productNo;
        if (col.name === name) col.searchbar = productNo;

        return col
      })
    }))
  }

  const handleChangePrice = evt => {
    const { name, value } = evt.target

    // resetPage()

    let price = null;

    if (value !== '' && value !== undefined)
      price = value;

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.name === name) col.Price = price;
        if (col.name === name) col.searchbar = price;

        return col
      })
    }))
  }

  const handleChangeMachine = evt => {
    const { name, value } = evt.target

    // resetPage()

    //console.log(filter);
    //console.log("handleChangeMachine");

    let machineId = null;

    if (value !== '' && machines.find(obj => obj.Name === value) !== undefined)
      machineId = machines.find(obj => obj.Name === value).MachineId;

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.name === name) col.MachineId = machineId;
        if (col.name === name) col.searchbar = value;

        return col
      })
    }))
  }

  const handleChangeTerminal = evt => {
    const { name, value } = evt.target

    // resetPage()

    let terminal = null;

    if (value !== '' && terminals.find(obj => obj.Name === value) !== undefined)
      terminal = terminals.find(obj => obj.Name === value);

    terminal && setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.name === name) {
          col.TerminalId = terminal.TerminalId
          col.TerminalSerialNo = String(terminal.Name).split(" ")[1]
        };
        if (col.name === name) col.searchbar = value

        return col
      })
    }))
  }

  const handleChangeTerminalType = evt => {
    const { name, value } = evt.target

    // resetPage()

    let terminalTypeId = null;

    if (value !== '' && terminalTypes.find(obj => obj.Name === value) !== undefined)
      terminalTypeId = terminalTypes.find(obj => obj.Name === value).TerminalTypeId;

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.name === name) col.TerminalTypeId = terminalTypeId;
        if (col.name === name) col.searchbar = value

        return col
      })
    }))
  }

  const handleChangePaymentType = evt => {
    const { name, value } = evt.target

    // resetPage()

    let paymentTypeId = null;

    if (value !== '' && paymentTypes.find(obj => obj.Name === value) !== undefined)
      paymentTypeId = paymentTypes.find(obj => obj.Name === value).PaymentTypeId;

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.name === name) col.PaymentTypeId = paymentTypeId;
        if (col.name === name) col.searchbar = value

        return col
      })
    }))
  }
  const handleFocusMachine = evt => {
    const { name, value } = evt.target

    //console.log(filter);
    //console.log("handleFocusMachine");

    // resetPage()

    let machineId = null;
    evt.target.value = '';

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.name === name) col.MachineId = null;
        if (col.name === name) col.searchbar = null;

        return col
      })
    }))
  }

  const handleFocusProductNo = evt => {
    const { name } = evt.target

    // resetPage()

    evt.target.value = '';

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.name === name) col.ProductNo = null;
        if (col.name === name) col.searchbar = null;

        return col
      })
    }))
  }

  const handleFocusPrice = evt => {
    const { name } = evt.target

    // resetPage()

    evt.target.value = '';

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.name === name) col.Price = null;
        if (col.name === name) col.searchbar = null;

        return col
      })
    }))
  }

  const handleFocusTerminal = evt => {
    const { name } = evt.target

    // resetPage()

    evt.target.value = '';

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.name === name) col.TerminalId = null;
        if (col.name === name) col.TerminalSerialNo = null;
        if (col.name === name) col.searchbar = null;

        return col
      })
    }))
  }

  const handleFocusTerminalType = evt => {
    const { name } = evt.target

    // resetPage()

    evt.target.value = '';

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.name === name) col.TerminalTypeId = null;
        if (col.name === name) col.searchbar = null;

        return col
      })
    }))
  }

  const handleFocusPaymentType = evt => {
    const { name } = evt.target

    // resetPage()

    evt.target.value = '';

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.name === name) col.PaymentTypeId = null;
        if (col.name === name) col.searchbar = null;

        return col
      })
    }))
  }

  return (
      <div className="mb-4 rounded border bg-fade p-3 user-select-none">
        <div className="row px-3">
          {filter.columns
              .filter(col => !col.disabled)
              .map((col, idx) => (
                  <div
                      className={
                        "col-12 col-sm-6 col-md-4 col-lg-3 border px-2 py-3 position-relative d-flex flex-column justify-content-center"
                      }
                      key={idx}
                  >
                    {col.type === "threeStateBool" && (
                        <><h6 className="text-center mb-0">{col.name}</h6>
                          <select
                              className="mt-2 form-control form-control-sm"
                              defaultValue={col.selectbar}
                              name={col.name}
                              onChange={handleChangeSelectbar}
                              autoComplete="off"
                          >
                            < option value={""}> {TRL_Pack.filter.unknown} </ option>
                            < option value={"true"}> {TRL_Pack.filter.yes} </ option>
                            < option value={"false"}> {TRL_Pack.filter.no} </ option>
                          </select>
                        </>
                    )}
                    {col.type === "lookup" && col.name === "Maszyna" && (
                        <>
                          <h6 className="text-center mb-0">{col.name}</h6>
                          <input
                              onFocus={handleFocusMachine}
                              className="mt-2 form-control form-control-sm"
                              placeholder={TRL_Pack.searchbarPlaceholder}
                              defaultValue={col.searchbar}
                              list="MachinesLookup"
                              name={col.name}
                              onChange={handleChangeMachine}
                              autoComplete="off"
                          />
                          {(<datalist id="MachinesLookup">
                            {machines.map((entry, idx) => (
                                    <option value={entry.Name} key={idx} />
                                )
                            )}
                          </datalist>)
                          }
                        </>
                    )}
                    {col.type === "lookup" && col.name === "Terminal" && (
                        <>
                          <h6 className="text-center mb-0">{col.name}</h6>
                          <input
                              onFocus={handleFocusTerminal}
                              className="mt-2 form-control form-control-sm"
                              placeholder={TRL_Pack.searchbarPlaceholder}
                              defaultValue={col.searchbar}
                              list="TerminalsLookup"
                              name={col.name}
                              onChange={handleChangeTerminal}
                              autoComplete="off"
                          />
                          {(<datalist id="TerminalsLookup">
                            {terminals.map((entry, idx) => (
                                    <option value={entry.Name} key={idx} />
                                )
                            )}
                          </datalist>)
                          }
                        </>
                    )}
                    {col.type === "lookup" && col.name === "Typ terminala" && (
                        <>
                          <h6 className="text-center mb-0">{col.name}</h6>
                          <input
                              onFocus={handleFocusTerminalType}
                              className="mt-2 form-control form-control-sm"
                              placeholder={TRL_Pack.searchbarPlaceholder}
                              defaultValue={col.searchbar}
                              list="TerminalTypeLookup"
                              name={col.name}
                              onChange={handleChangeTerminalType}
                              autoComplete="off"
                          />
                          {(<datalist id="TerminalTypeLookup">
                            {terminalTypes.map((entry, idx) => (
                                    <option value={entry.Name} key={idx} />
                                )
                            )}
                          </datalist>)
                          }
                        </>
                    )}
                    {col.type === "lookup" && col.name === "Typ płatności" && (
                        <>
                          <h6 className="text-center mb-0">{col.name}</h6>
                          <input
                              onFocus={handleFocusPaymentType}
                              className="mt-2 form-control form-control-sm"
                              placeholder={TRL_Pack.searchbarPlaceholder}
                              defaultValue={col.searchbar}
                              list="PaymentTypeLookup"
                              name={col.name}
                              onChange={handleChangePaymentType}
                              autoComplete="off"
                          />
                          {(<datalist id="PaymentTypeLookup">
                            {paymentTypes.map((entry, idx) => (
                                    <option value={entry.Name} key={idx} />
                                )
                            )}
                          </datalist>)
                          }
                        </>
                    )}
                    {/* Adam: commented out for Orange testing */}
                    {/* {col.type === "text" && col.name === "Nr wyboru" && (
                <div className='d-flex flex-column justify-content-start'>
                  <h6 className="text-center mb-2">{col.name}</h6>
                  <div className='d-inline justify-content-center'>
                    <TextInput
                      name={col.name}
                      onFocus={handleFocusProductNo}
                      handleChange={handleChangeProductNo}
                      value={col.searchbar}
                    ></TextInput>
                  </div>
                </div>
              )} */}
                    {/* Adam: commented out for Orange testing */}
                    {/* {col.type === "text" && col.name === "Cena" && (
                     <div className='d-flex flex-column justify-content-start'>
                     <h6 className="text-center mb-2">{col.name}</h6>
                     <div className='d-inline justify-content-center'>
                  <TextInput
                    name={col.name}
                    onFocus={handleFocusPrice}
                    handleChange={handleChangePrice}
                    value={col.searchbar}
                  ></TextInput>   </div></div>
              )} */}
                    {col.type === "datetimeRange" && (
                        <>
                          <div className='d-flex flex-column justify-content-center'>
                            <h6 className="text-center mb-1">{col.name}</h6>
                            <div className='d-inline justify-content-center'>
                              Od <input type="datetime-local"
                                        style={{ border: "1px solid rgba(0, 0, 0, .1)", padding: ".375rem .75rem" }}
                                        className="rounded date" name={col.name + "_from"}
                                        onChange={handleChangeDateTimeRangeFrom}
                                        value={moment(col.dateTimeRangeFrom).format('YYYY-MM-DDTHH:mm:ss')}></input>
                            </div>
                            <div className='d-inline justify-content-center'>
                              Do <input type="datetime-local"
                                        style={{ border: "1px solid rgba(0, 0, 0, .1)", padding: ".375rem .75rem" }}
                                        className="rounded date" name={col.name + "_to"}
                                        onChange={handleChangeDateTimeRangeTo}
                                        value={moment(col.dateTimeRangeTo).format('YYYY-MM-DDTHH:mm:ss')}></input>
                            </div>
                            <div className='d-inline justify-content-center'>
                              <button
                                  className="m-1 btn btn-outline-primary btn-sm"
                                  onClick={handleChangeDateTimeRangeToday}
                              >
                                {TRL_Pack.filter.dateRangeToday}
                              </button>
                              <button
                                  className="m-1 btn btn-outline-primary btn-sm"
                                  onClick={handleChangeDateTimeRangePreviousWeek}
                              >
                                {TRL_Pack.filter.dateRangePreviousWeek}
                              </button>
                              <button
                                  className="m-1 btn btn-outline-primary btn-sm"
                                  onClick={handleChangeDateTimeRangeLastWeek}
                              >
                                {TRL_Pack.filter.dateRangeLastWeek}
                              </button>
                              <button
                                  className="m-1 btn btn-outline-primary btn-sm"
                                  onClick={handleChangeDateTimeRangePreviousMonth}
                              >
                                {TRL_Pack.filter.dateRangePreviousMonth}
                              </button>
                              <button
                                  className="m-1 btn btn-outline-primary btn-sm"
                                  onClick={handleChangeDateTimeRangeLastMonth}
                              >
                                {TRL_Pack.filter.dateRangeLastMonth}
                              </button>
                              <button
                                  className="m-1 btn btn-outline-primary btn-sm"
                                  onClick={handleChangeDateTimeRangeWhenever}
                              >
                                {TRL_Pack.filter.dateRangeWhenever}
                              </button>
                            </div>
                          </div>
                        </>
                    )}
                  </div>
              ))}
          <>
            <div className={
              "col-12 col-sm-6 col-md-4 col-lg-3 border px-2 py-3 position-relative d-flex flex-column justify-content-center"
            }>
              {localStorage.getItem("clientId") !== "console" &&
                  <Tags resetPage={resetPage} filter={filter} setFilter={setFilter} enableMachineTags={true}/>}
            </div>
          </>

        </div>
        <hr className="my-2" />
        <div className="row align-items-center justify-content-center">
          {filter.rowsPerPageOptions && (
              <div className="col-12 col-md-6 col-lg-4 d-flex align-items-center justify-content-center">
                <h6 className="mb-0">{TRL_Pack.filter.rowsPerPage}</h6>
                <select
                    className="form-control form-control-sm ml-2"
                    style={{ maxWidth: 100 }}
                    name="rowsPerPage"
                    value={filter.rowsPerPage}
                    onChange={handleChange}
                >
                  {filter.rowsPerPageOptions.map((num, idx) => (
                      <option key={idx}>{num}</option>
                  ))}
                </select>
              </div>
          )}
          {setIsFlatTable && (
              <OverlayTrigger
                  overlay={<Tooltip>Widok uproszczony</Tooltip>}
              >
                <div className="col-12 col-md-6 col-lg-1 d-flex align-items-center justify-content-center">
                  <button className={`fa-s btn align-content-center`} onClick={() => {
                    setIsFlatTable(!isFlatTable)
                  }} style={{ height: '3vh', backgroundColor: 'transparent' }}>
                    <i
                        className={`fas fa-toggle-${isFlatTable ? `on text-primary` : `off text-secondary`} fa-lg`}
                    />
                  </button>
                </div>
              </OverlayTrigger>
          )}
          {filter.sortByColumns && (
              <div className="col-12 col-md-6 col-lg-4 mb-3 d-flex align-items-center justify-content-center">
                <h6 className="mb-0">{TRL_Pack.filter.sortBy}</h6>
                <select
                    className="form-control form-control-sm ml-2"
                    style={{ maxWidth: 175 }}
                    name="sortBy"
                    defaultValue="none"
                    // value={filter.sortBy}
                    onChange={handleChange}
                >
                  <option defaultValue value={""}>{TRL_Pack.products.sharedProductLackOption}</option>
                  {columns
                      .filter(col => col.sortable)
                      .map((col, idx) => (
                          <Fragment key={idx}>
                            <option value={`${col.id} | asc | ${col.type}`}>
                              {col.name} &nbsp; &#8681;
                            </option>
                            <option value={`${col.id} | desc | ${col.type}`}>
                              {col.name} &nbsp; &#8679;
                            </option>
                          </Fragment>
                      ))}
                </select>
              </div>
          )}
          <button
              className="d-inline m-2 btn btn-primary float-right"
              onClick={search}
          >
            {TRL_Pack.filter.search}
          </button>
          <button
              className="d-inline m-2 btn btn-primary float-right"
              onClick={resetFilter}
          >
            {TRL_Pack.filter.defaultFilter}
          </button>
          <button
              className="d-inline m-2 btn btn-primary float-right"
              onClick={toggleFilter}
          >
            {TRL_Pack.filter.closeFilter}
          </button>
        </div>
      </div>
  )
}