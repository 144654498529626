import React, { useState, useEffect, useContext, useMemo } from 'react'
import { LangContext } from '../../../context/lang-context'
import { NavigationContext } from '../../../context/navigation-context'
import useForm from '../../../hooks/form-hook'
import useFetch from '../../../hooks/fetchMSSQL-hook'
import useFilter from '../../../hooks/filter-hook'
import NoResults from '../../../components/NoResults/NoResults'
import MachineForm from '../../../components/Modals/MachineForm'
import { Link } from 'react-router-dom'
import MachinesFilter from './MachinesFilter'

import SearchInput from '../../../components/SearchInput/SearchInput'
import filterItems from '../../../util/filterItems'

const Machines = () => {
  const { TRL_Pack } = useContext(LangContext)
  const { fetchMssqlApi } = useFetch()
  const { setHeaderData } = useContext(NavigationContext)
  // const { searchedText, updateSearchedText } = useFilter()

  const { form, openForm, closeForm } = useForm()

  const [machines, setMachines] = useState([])
  const [searchText, setSearchText] = useState("")
  const [machiesSerialNo, setMachinesSerialNo] = useState([])
  const [terminalList, setTerminalList] = useState([])
  const [machinesList, setMachinesList] = useState([])
  const [customerList, setCustomerList] = useState([])
  const getMachines = (data = {MachineId: null, CustomerId: null, TerminalId: null}) => {
    fetchMssqlApi(
        'machines-console'
        , {method: "POST", data, hideNotification: true}
        , machines => setMachines(machines)
        ,() => {}
    )
  }
  const getMachinesList = () => {
    fetchMssqlApi('machine-list', {method: "POST", data: {customerAbbr: "console", customerId: null}, hideNotification: true}, machines => setMachinesList(machines))
  }

  const getTerminals = () => {
    fetchMssqlApi('terminal-list', {method: "POST", data: {customerAbbr: localStorage.getItem('clientId')}, hideNotification: true}, terminals => setTerminalList(terminals))
  }
  const getCustomers = () => fetchMssqlApi('clients', {}, clients => setCustomerList(clients))

  useEffect(() => {
    machines.map(machine => {
      machine.MachineSerialNo.includes("_")
          ? setMachinesSerialNo(prev => [...prev, machine.MachineSerialNo.split('_')[1]])
          : setMachinesSerialNo(prev => [...prev, machine.MachineSerialNo])
    })
  } , [machines])

  useEffect(() => {
    setHeaderData({ text: TRL_Pack.machines.header })
    getMachinesList()
    getTerminals()
    getCustomers()
    getMachines()
    resetFilter()
  }, [])
  const defaultFilter = {
    name: "machines",
    showIndexes: true,
    page: 1,
    rowsPerPage: 50,
    // rowsPerPageOptions: [10, 25, 50],
    visible: true,
    sortByColumns: true,
    sortBy: '3 | asc | text',
    activeTags: [],
    columns: [
      {
        id: 7,
        name: "Automat",
        sortable: true,
        searchable: true,
        type: 'lookup',
        // solid: true,
        value: null,
        save: null,
      },
      {
        id: 8,
        name: "Terminal",
        sortable: true,
        searchable: true,
        type: 'lookup',
        // solid: true,
        value: null,
        save: null,
      },
      {
        id: 4,
        name: "Klient",
        sortable: true,
        searchable: true,
        type: 'lookup',
        // solid: true,
        disabled: false,
        save: null,
      },


    ]}
  const [filter, setFilter] = useState(() => {
    if (localStorage.getItem('machinesConsoleFilter')) {
      return JSON.parse(localStorage.getItem('machinesConsoleFilter'))
    } else return defaultFilter
  })
  const sortRows = (a, b) => {
    const [id, order, type] = filter.sortBy.split(' | ')
    const col = Object.keys(machines[0])[Number(id) - 1]
    // console.log(machines)
    if (a[col] === b[col]) return 0
    else if (a[col] === null) return 1
    else if (b[col] === null) return -1

    let valueA, valueB
    if (type === "lookup") {
      valueA = a[col].toUpperCase()
      valueB = b[col].toUpperCase()


      // Number().toLocaleString(undefined, {minimumFractionDigits: 2}) num => str '1 245 151,50'
    } else return 0

    if (order === 'asc') return valueA < valueB ? -1 : 1
    else return valueA < valueB ? 1 : -1
  }
  useEffect(() => localStorage.setItem("machinesConsoleFilter", JSON.stringify(filter)), [filter])
  const resetFilter = () => {setFilter(defaultFilter)
    getMachines()}
  const toggleFilter = () => setFilter(prev => ({ ...prev, visible: !prev.visible }))
  const filteringMachines = () => {
    let arr = []
    machines.map(machine => {
      const {CustomerName, MachineName, MachineSerialNo, Abbreviation, TerminalSerialNo} = machine
      const query = String(CustomerName + " " + MachineName + " " + MachineSerialNo + Abbreviation + TerminalSerialNo ).toLowerCase()
      if (query.includes(searchText.toLowerCase())) arr.push(machine)
    })
    return (searchText.length) ?
        arr
        : machines
  }
  const filteredMachines = useMemo(() => filteringMachines(), [searchText, machines])
  // const filteredMachines = filteringMachines()
  // machines && machines.filter(( CustomerName, MachineName, MachineSerialNo) => filterItems(searchedText, CustomerName, MachineName, MachineSerialNo))

  const CheckboxComponent = ({state}) => <input type='checkbox' onChange={() => {}} checked={state}></input>
  const checkbox = {
    display: "flex",
    width:"100%",
    height: "100%",
    alignItems:"stretch",
    justifyContent: "center"
  }
  const search = () => {
    // console.log({
    //   1: filter.columns[2].value,
    //   2: filter.columns[0].value,
    //   3: filter.columns[1].value
    // })
    const getTerminalId = terminalName => {
      // console.log(terminalList)
      return terminalList.find(terminal => terminal.Name.toUpperCase() === terminalName)?.TerminalId || null
    }
    const getMachineId = machineName => {
      // console.log(machinesList.find(machine => machine.Name ? machine.SerialNo === String(machineName).split(" ").at(-1)?.replace(/[^a-z0-9]/gi, '') : `(${machine.SerialNo})` === machineName)?.MachineId || null)
      return machinesList.find(machine => machine.Name ? machine.SerialNo === String(machineName).split(" ").at(-1)?.replace(/[^a-z0-9]/gi, '') : `(${machine.SerialNo})` === machineName)?.MachineId || null
    }
    const getCustomerId = customerName => {
      // console.log(customerName)
      // console.log(customerList.find(customer => customer.Abbreviation === customerName?.split("( ").at(-1).replace(")", "") || customerName)?.CustomerId)
      // return customerList.find(customer => `${customer?.Name} (${customer?.Abbreviation.toLowerCase()})` === customerName)?.CustomerId || null 
      return customerList.find(customer => `${customer?.Name} (${customer?.Abbreviation.toLowerCase()})` === customerName)?.CustomerId || null
    }
    const columns = filter.columns
    const payload = {
      CustomerId: getCustomerId(columns[2].value) || null,
      MachineId: getMachineId(columns[0].value) || null,
      TerminalId: getTerminalId(columns[1].value) || null
    }
    getMachines(payload)
  }
  return (
      machines && (
          <div>
            {/* <input type='text' value={searchText}
          onChange={e => setSearchText(e.target.value)}
          placeholder='Szukaj...' className="form-control rounded-left border-right-0"
        /> */}
            <nav className="mb-4 d-flex justify-content-center">
              {toggleFilter && (
                  <button className="btn ml-2 my-auto" onClick={toggleFilter}>
                    <i
                        className={`fas fa-filter ${filter.visible ? 'text-primary' : 'text-secondary'
                        }`}
                    />
                  </button>
              )}
            </nav>
            {filter.visible && <MachinesFilter filter={filter}
                                               setFilter={setFilter}
                                               terminals={terminalList}
                                               machines={machinesList}
                                               customers={customerList}
                                               toggleFilter={toggleFilter}
                                               resetFilter={resetFilter}
                                               search={search}
                                               sortRows={sortRows}/>}

            {!filteredMachines.length ? (
                <NoResults buttonText={TRL_Pack.machine.addMachine} onClick={openForm()} />
            ) : (
                <>
                  <div>
                    {/* <SearchInput onSearch={updateSearchedText} /> */}
                    {/* <FilterEx filter={defaultFilter}
              columns={defaultFilter.columns}
              data={machines}
              /> */}
                    <button
                        className="d-block btn btn-link text-decoration-none ml-auto my-2 mr-1"
                        onClick={openForm()}
                    >
                      <i className="fas fa-plus mr-2" /> Dodaj automat
                    </button>
                  </div>

                  <div className="overflow-auto">


                    <table className="table table-striped border">
                      <thead>
                      <tr>
                        <th>Klient</th>
                        <th style={{textAlign: "center"}}>Baza danych</th>
                        <th>Automat</th>
                        <th>Terminal</th>
                        <th style={{textAlign: 'center'}}>Rozliczanie sprzedaży przez Transakcje</th>
                        <th style={{textAlign: 'center'}}>Rozliczanie sprzedaży przez Transakcje R24 VMC</th>
                      </tr>
                      </thead>
                      <tbody>
                      {filteredMachines
                          .filter(machine => machine.MachineName)
                          .sort(sortRows)
                          .map((machine, idx) => (
                              <tr key={idx}>
                                <td>{machine.CustomerName}</td>
                                <td>
                                  <div style={{textAlign: "center"}}>
                                    {machine.Abbreviation.toLowerCase() || <bold>brak</bold>}
                                  </div>
                                </td>
                                <td>
                                  <Link
                                      to={`/machine/${machine.MachineId}`}
                                      className="btn btn-link font-size-inherit text-reset text-decoration-none p-1"
                                  >
                                    { machine.MachineSerialNo !== machine.MachineName ? `${machine.MachineName} (${machine.MachineSerialNo})` : machine.MachineSerialNo}
                                  </Link>
                                </td>

                                <td>{(machine.TerminalTypeId == 1) && `Vendotek  ${machine.TerminalSerialNo}`
                                    ||(machine.TerminalTypeId == 2) && `PAX  ${machine.TerminalSerialNo}`
                                    ||(machine.TerminalTypeId == 3) && `NAYAX  ${machine.TerminalSerialNo}`
                                    || ""} </td>
                                <td><div style={checkbox}>{<CheckboxComponent state={machine.UseTrxForSales}/>}</div></td>
                                <td><div style={checkbox}>{<CheckboxComponent state={machine.UseVMCTrx}/>}</div></td>

                              </tr>

                          ))}
                      </tbody>
                    </table>
                  </div>
                </>
            )}
            {form && <MachineForm serialNoArr={machiesSerialNo} postSubmit={getMachines} handleClose={closeForm}/>}
          </div>
      )

  )
}

export default Machines;