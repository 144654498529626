import { useContext } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { NotificationContext } from "../context/notification-context";
import { LoaderContext } from "../context/loader-context";
import { ErrorContext } from "../context/error-context";
import { API_URL } from "../config/config";
import authHook from "./auth-hook";
export default () => {
  const { logout, isAuth } = authHook();
  const { incrementRequests, decrementRequests } = useContext(LoaderContext);
  const { ErrorNotification, SuccessNofication } =
    useContext(NotificationContext);
  const { setError } = useContext(ErrorContext);
  const history = useHistory();

  const fetchMssqlApi = (
    path,
    {
      method = "GET",
      data = null,
      hideNotification,
      params = null,
      showLoader = true,
    } = {},
    onSuccess = () => {},
    onError = () => {
      history.push(`/`);
    },
    offlineFunc = () => {}
  ) => {
    if (!navigator.onLine) {
      offlineFunc(onSuccess = () => {}, onError = () => {});
      return
    }
    showLoader && incrementRequests();
    return axios({
      method,
      url: `${API_URL}/api/${path}`,
      headers: {},
      data,
      params,
    })
      .then((res) => {
        if (res.status > 299) throw new Error();

        decrementRequests();
        onSuccess(res.data);
        if (method !== "GET") {
          // console.log(res)
          if (!hideNotification) SuccessNofication(res.data.message);
        }
      })
      .catch((err) => {
        showLoader && decrementRequests();
        if (err.response?.status === 401) {
          logout().then(() => {
            return window.location.reload();
          });
        }
        if (onError) return onError(err);
        if (err.response.data.message === "jwt malformed")
          window.location.reload();
        if (method === "GET")
          setError(err.response.data || "Failed to fetch data.");
        else ErrorNotification(err.response.data || err.toString());

        // console.log(err.response || err.response)
        console.group(err.response);
      });
  };

  return { fetchMssqlApi };
};
