import React, {useEffect, useRef} from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export default function PeriodBar({
  data,
  labels,
  count,
  title
}) {
  const chartRef = useRef(null);
  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.update();
    }
  }, [data, labels, count]);
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Sprzedaż wartość",
        data: data,
        backgroundColor: "#0140BE",
        countData: count,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: title,
      },
      plnFormat: true,
      tooltip: {
        mode: "index",
        intersect: false,
        enabled: true,
        callbacks: {
          label: (ctx) => {
            const dataset = ctx.chart.data.datasets[ctx.datasetIndex];
            const price = ctx.raw;
            const countData = dataset.countData;

            if (!countData) {
              return `${ctx.label}: ${price} zł`;
            }

            const count = countData[ctx.dataIndex] || 0;
            return `${new Intl.NumberFormat('pl-PL', {
              style: 'currency',
              currency: 'PLN',
            }).format(price)}  (${count} szt.)`;
          },
        },
      },
    },
  };

  return <Bar data={chartData} options={options} />;
}