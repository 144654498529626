import React from "react";
import '../plugins/CustomScrollbarStyle.css';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
      // <td
      //   className="w-100 mb-2"
      //   style={{ whiteSpace: "nowrap", maxWidth: `${6 * 48}px`, textOverflow: "ellipsis", overflow: "hidden"}}
      // >
      //   {machineFullName}
      // </td>

      
      export default function MachineRecordV3({ machineFullName, dataset }) {
      
        return (
          <tr>
            <td
              className="sticky-column"
              style={{
                position: "sticky",
                left: 0,
                background: "white",
                zIndex: 2, 
                padding: 0,

              }}
            >
              <div style={{
                whiteSpace: "nowrap",
                maxWidth: `${6 * 48}px`, 
                textOverflow: "ellipsis", overflow: "hidden",
                borderRight: ".2px solid rgba(200,200,200,1)", 
                borderTop: 0,
                width: "100%",
                height: "100%",
                paddingRight: ".5rem"
              }}>

                {machineFullName}
              </div>
            </td>
      
            {dataset.map((entry, index) => (
              <td
                key={index}
                style={{
                  borderRight: ".2px solid grey", 
                  // boxShadow: "inset 2 0 0 0px rgba(200,200,200,1)",
                  // borderLeft: !index && "1px solid grey",
                  textAlign: "center",
                  padding: 0
                }}
              >
                <div className="d-flex" name="timeline">
                  {Object.values(entry)[0].map((val, idx) => (
                    <OverlayTrigger
                      key={`${idx}-${index}`}
                      overlay={<Tooltip className={`d-${val ? "block" : "none"}`}>{`${idx <= 9 ? `0${idx}` : idx}:00 (${val})`}</Tooltip>}
                    >
                      <div
                        className={`bg-${
                          (!val && "light") || (val > 0 && val <= 3 && "warning") || "success"
                        }`}
                        style={{
                          width: "6px",
                          height: "15px",
                          display: "inline-block",
                        }}
                      ></div>
                    </OverlayTrigger>
                  ))}
                </div>
              </td>
            ))}
          </tr>
        );
      }