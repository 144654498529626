import React from "react";
import ChartFilter from "../../components/ChartFilter";

export default function ValueSalesChartFilter({
  salesChartFilter,
  handleChangePaymentType,
  handleSubmit,
  machines,
  handleChangeMachine = () => {},
}) {
  return (
    <ChartFilter searchFunction={handleSubmit} key={"ValueSalesChartFilter"}>
      <div className="w-100">
        <h6 className="text-center mb-1">Typ płatności</h6>
        <div className="mt-2 form-check">
          <input
            className="form-check-input"
            type="radio"
            name="paymentType"
            id="paymentTypeAll"
            value=""
            checked={!salesChartFilter.paymentType}
            onChange={handleChangePaymentType}
          />
          <label className="form-check-label" htmlFor="paymentTypeAll">
            Razem
          </label>
        </div>
        <div className="mt-2 form-check">
          <input
            className="form-check-input"
            type="radio"
            name="paymentType"
            id="paymentTypeCard"
            value="1"
            checked={salesChartFilter.paymentType === "1"}
            onChange={handleChangePaymentType}
          />
          <label className="form-check-label" htmlFor="paymentTypeCard">
            Karta
          </label>
        </div>
        <div className="mt-2 form-check">
          <input
            className="form-check-input"
            type="radio"
            name="paymentType"
            id="paymentTypeCash"
            value="2"
            checked={salesChartFilter.paymentType === "2"}
            onChange={handleChangePaymentType}
          />
          <label className="form-check-label" htmlFor="paymentTypeCash">
            Gotówka
          </label>
        </div>
      </div>
      <div className="w-100">
        <div>
          <div className="mt-2">
            <h6 className="text-center mb-1">Automat</h6>
            <input
              className="mt-2 form-control form-control-sm"
              placeholder="Wybierz automat"
              list="machines"
              name="machine"
              onChange={handleChangeMachine}
              autoComplete="off"
              defaultValue={salesChartFilter.machineName}
              onFocus={(e) => e.target.value = ""}
            />
            <datalist id="machines">
              {machines && machines.map((machine, idx) => (
                <option value={`${machine.Name} (${machine.SerialNo})`} key={idx} />
              ))}
            </datalist>
          </div>
        </div>
      </div>
    </ChartFilter>
  );
}
