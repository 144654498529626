import React, { useState, useEffect, memo, useContext } from "react";
import moment from "moment/moment";
import useFetch from "../../../hooks/fetchMSSQL-hook";
import { ValueSalesChart } from "./components/charts/ValueSalesChart";
import PeriodBar from "./components/PeriodBar";
import YearToYearBar from "./components/charts/YearToYearBar";
import TerminalsInfo from "./components/TerminalsInfo";
import BestAndWorstMachineAndProducts from "./components/BestAndWorstMachineAndProducts";
import PeriodBarChartFilter from "./components/filters/PeriodBarChartFilter";
import YearToYearBarFilter from "./components/filters/YearToYearBarFilter";
import ValueSalesChartFilter from "./components/filters/ValueSalesChartFilter";
import validFilter from "./utils/validFilter";
import { NavigationContext } from "../../../context/navigation-context";
import enablePlugins from "./components/plugins/EnablePlugins";
import FilteredMachineSales from "./components/modals/FilteredMachineSalesV2";

export default function StatView() {
  const { fetchMssqlApi } = useFetch();
  const [sellData, setSalesData] = useState([]);
  const [machineStatFilterData, setMachineSalesData] = useState([]);
  const [productSalesData, setProductSalesData] = useState([]);
  const [terminalsActivity, setTerminalsActivity] = useState(null);
  const [yearToYear, setYearToYear] = useState([]);
  const [periodSales, setPeriodSales] = useState([]);
  const [machinesList, setMachinesList] = useState([]);
  const [machinesSalesForLast10Daya, setMachinesSalesForLast10Daya] = useState(
    []
  );
  const [showModal, setShowModal] = useState(false);

  const defaultFilters = {
    fromCreateDateTime: moment().subtract(1, "W").format("YYYY-MM-DD"),
    toCreateDateTime: moment().format("YYYY-MM-DD"),
    fromYear: moment().subtract(1, "Y").format("YYYY"),
    toYear: moment().format("YYYY"),
    paymentType: "1",
    IsVisible: false,
    machineName: null,
  };
  const hideModal = () => setShowModal(false);

  const getMachineSerialNo = ({ machineName }) => {
    if (!machineName || !machinesList.length) if (!machineName) return null;
    if (!machinesList.length) return null;

    const machine = machinesList.find(
      ({ Name, SerialNo }) => `${Name} (${SerialNo})` === machineName
    );
    return machine?.SerialNo || null;
  };
  const getDefaultFilters = (filterName = "general") => {
    try {
      switch (filterName) {
        case "general": {
          const filter = JSON.parse(
            localStorage.getItem("statFilters")
          ).generalSalesChartFilter;
          return {
            ...defaultFilters,
            paymentType: filter.paymentType,
            IsVisible: filter.IsVisible,
          };
        }
        case "period": {
          const filter = JSON.parse(
            localStorage.getItem("statFilters")
          ).periodSellFilter;
          return {
            ...defaultFilters,
            paymentType: filter.paymentType,
            IsVisible: filter.IsVisible,
            toCreateDateTime: moment(defaultFilters).format("YYYY-MM"),
            fromCreateDateTime: null,
          };
        }
        case "yearToYear": {
          const filter = JSON.parse(
            localStorage.getItem("statFilters")
          ).yearToYearFilter;
          return {
            ...defaultFilters,
            paymentType: filter.paymentType,
            IsVisible: filter.IsVisible,
          };
        }
        case "sellData": {
          const filter = JSON.parse(
            localStorage.getItem("statFilters")
          ).sellDataFilter;
          return {
            ...defaultFilters,
            paymentType: filter.paymentType,
            IsVisible: filter.IsVisible,
          };
        }
        default:
          return defaultFilters;
      }
    } catch (e) {
      console.log(e);
      return defaultFilters;
    }
  };
  // TODO - split filters to separate components
  const [generalSalesChartFilter, setGeneralSalesChartFilter] = useState(null);
  const [sellDataFilter, setSalesDataFilter] = useState(
    getDefaultFilters("sellData")
  );
  const [periodSellFilter, setPeriodSellFilter] = useState(
    getDefaultFilters("period")
  );
  const [yearToYearFilter, setYearToYearFilter] = useState(
    getDefaultFilters("yearToYear")
  );

  const plnFormatter = Intl.NumberFormat("pl-PL", {
    style: "currency",
    currency: "PLN",
  });

  const filterTerminalsCount = (terminals) => {
    const counts = {
      lastHourTerminalsCount: 0,
      upTo72HoursTerminalsCount: 0,
      inactiveTerminalsCount: 0,
    };
    terminals &&
      terminals.forEach(({ LastTrxCreateDateTimeInHour, SerialNo }) => {
        // const diffHours = -1 * moment(LastTrxCreateDateTime).diff(moment(), "H");
        if (
          !LastTrxCreateDateTimeInHour ||
          isNaN(!LastTrxCreateDateTimeInHour) ||
          +LastTrxCreateDateTimeInHour > 72
        ) {
          counts.inactiveTerminalsCount++;
          return;
        }
        if (+LastTrxCreateDateTimeInHour < 24) {
          counts.lastHourTerminalsCount++;
          return;
        }
        if (
          +LastTrxCreateDateTimeInHour >= 24 &&
          +LastTrxCreateDateTimeInHour < 72
        ) {
          counts.upTo72HoursTerminalsCount++;
          return;
        }
      });

    return counts;
  };

  const getMachineSalesData = async () => () =>
    fetchMssqlApi(
      "stat/machine-sales",
      {
        method: "POST",
        data: {
          fromCreateDateTime: moment().subtract(3, "days").format("YYYY-MM-DD"),
          toCreateDateTime: moment().format("YYYY-MM-DD"),
        },
        hideNotification: true,
      },
      setMachineSalesData,
      (err) => console.log(err)
    );

  const getProductsSalesData = async () =>
    fetchMssqlApi(
      "stat/product-sales",
      {
        method: "POST",
        data: {
          fromCreateDateTime: moment().subtract(3, "days").format("YYYY-MM-DD"),
          toCreateDateTime: moment().format("YYYY-MM-DD"),
        },
        hideNotification: true,
      },
      setProductSalesData,
      (err) => console.log(err)
    );

  const getYearToYearSalesData = async () =>
    validFilter(yearToYearFilter)
      .then(() =>
        fetchMssqlApi(
          "stat/year-to-year-sales",
          {
            method: "POST",
            data: {
              ...yearToYearFilter,
              machineSerialNo: getMachineSerialNo(yearToYearFilter),
            },
            hideNotification: true,
          },
          setYearToYear,
          (err) => console.log(err)
        )
      )
      .catch((err) => console.log(err));

  const getTerminalsActivity = async () =>
    fetchMssqlApi("/stat/terminals", {}, setTerminalsActivity, (err) => {});
  const getMachinesSalesStat = async () =>
    fetchMssqlApi(
      "stat/filtered-machines-stat",
      {},
      setTerminalsActivity,
      (err) => {}
    );

  const getComparingSalesData = async () =>
    validFilter(sellDataFilter).then(() =>
      fetchMssqlApi(
        "stat/sales",
        {
          method: "POST",
          data: {
            ...sellDataFilter,
            machineSerialNo: getMachineSerialNo(sellDataFilter),
          },
          hideNotification: true,
        },
        setSalesData,
        (err) => console.log(err)
      )
    );

  const getPeriodSalesData = async () => {
    const fromCreateDateTime = moment(periodSellFilter.toCreateDateTime).format(
      "YYYY-MM-01"
    );
    const toCreateDateTime = moment(periodSellFilter.toCreateDateTime)
      .add(1, "M")
      .subtract(1, "d")
      .format("YYYY-MM-DD");

    return validFilter(periodSellFilter).then(() =>
      fetchMssqlApi(
        "stat/period-sales",
        {
          method: "POST",
          data: {
            ...periodSellFilter,
            toCreateDateTime,
            fromCreateDateTime,
            machineSerialNo: getMachineSerialNo(periodSellFilter),
          },
          hideNotification: true,
        },
        setPeriodSales,
        (err) => console.log(err)
      )
    );
  };
  const handleClickTerminalButton = (evt) => {
    const name = evt.currentTarget.closest("label").getAttribute("name");
    const terminalsActivityCaseTypes = new Map([
      ["lastHourTerminalsCount", 1],
      ["upTo72HoursTerminalsCount", 2],
      ["inactiveTerminalsCount", 3],
    ]);

    const terminalsPath = new URL(`${window.location.origin}/terminals`);
    terminalsPath.searchParams.set(
      "filterType",
      terminalsActivityCaseTypes.get(name)
    );

    window.open(terminalsPath.toString(), "_blank");
  };
  const getMachinesList = async () =>
    fetchMssqlApi("machines-list", {}, (machines) => setMachinesList(machines));

  useEffect(() => {
    try {
      periodSellFilter &&
        sellDataFilter &&
        yearToYearFilter &&
        localStorage.setItem(
          "statFilters",
          JSON.stringify({
            periodSellFilter,
            sellDataFilter,
            generalSalesChartFilter,
            yearToYearFilter,
          })
        );
    } catch (e) {
      console.log(e);
    }
  }, [periodSellFilter, sellDataFilter, yearToYearFilter]);

  const getInitData = async () => {
    getMachinesList();
    getComparingSalesData();
    // getMachineSalesData();
    // getProductsSalesData();
    periodSellFilter && periodSellFilter.IsVisible && getPeriodSalesData();
    yearToYearFilter && yearToYearFilter.IsVisible && getYearToYearSalesData();
    getTerminalsActivity();
    //   );
  };

  const setDefaultFilters = () => {
    setPeriodSellFilter({
      fromCreateDateTime: moment().subtract(1, "W").format("YYYY-MM-DD"),
      toCreateDateTime: moment().format("YYYY-MM-DD"),
      paymentType: null,
      IsVisible: false,
    });
    setSalesDataFilter({
      fromCreateDateTime: moment().subtract(1, "W").format("YYYY-MM-DD"),
      toCreateDateTime: moment().format("YYYY-MM-DD"),
      paymentType: null,
    });
    setGeneralSalesChartFilter({
      fromCreateDateTime: moment().subtract(1, "W").format("YYYY-MM-DD"),
      toCreateDateTime: moment().format("YYYY-MM-DD"),
      paymentType: null,
    });
    setYearToYearFilter({
      fromCreateDateTime: moment().subtract(1, "W").format("YYYY-MM-DD"),
      toCreateDateTime: moment().format("YYYY-MM-DD"),
      paymentType: null,
      IsVisible: false,
    });
  };

  useEffect(() => {
    getInitData();
    // enablePlugins()
  }, []);

  const months = [
    "Styczeń",
    "Luty",
    "Marzec",
    "Kwiecień",
    "Maj",
    "Czerwiec",
    "Lipiec",
    "Sierpień",
    "Wrzesień",
    "Październik",
    "Listopad",
    "Grudzień",
  ];

  const generateMachineData = (data, formatter) =>
    data.map(
      (item) => `${item.MachineName} - ${formatter.format(item.SummaryValue)} `
    );

  const generateProductData = (data, formatter) =>
    data.map(
      (item) =>
        `${item.ProductName} - ${item.SoldCount} szt. (${formatter.format(
          item.Price
        )}) `
    );

  const machineAndProductsGeneratorFunction = (type) => {
    switch (type) {
      case "bestMachines":
        return generateMachineData(
          machineStatFilterData.slice(0, 10),
          plnFormatter
        );
      case "worstMachines":
        return generateMachineData(
          machineStatFilterData
            .slice(10)
            .sort((a, b) => b.SummaryValue > a.SummaryValue),
          plnFormatter
        );
      case "bestProducts":
        return generateProductData(productSalesData.slice(0, 10), plnFormatter);
      case "worstProducts":
        return generateProductData(
          productSalesData.slice(10).sort((a, b) => b.SoldCount > a.SoldCount),
          plnFormatter
        );
      default:
        return [];
    }
  };

  const handleValueSalesChartFilterChange = (evt) => {
    const type = evt.target?.value;
    setSalesDataFilter((prev) => ({
      ...prev,
      paymentType: type,
    }));
  };
  const handleChangeMachine = (evt) => {
    const machineName = evt.target?.value;
    setSalesDataFilter((prev) => ({
      ...prev,
      machineName,
    }));
  };

  const handlePeriodSalesChartFilterChange = (evt) => {
    const { value, name, type } = evt.target;
    if (!name) return;
    let v = value;
    console.log(v);
    if (type === "number") {
      v = value.replace(/\D/g, "");
    }
    setPeriodSellFilter((prev) => ({
      ...prev,
      [name]: v,
    }));
  };

  const handleYearToYearPeriodFilterChange = (evt) => {
    const type = evt.target?.value;
    setYearToYearFilter((prev) => ({
      ...prev,
      paymentType: type,
    }));
    getYearToYearSalesData(type);
  };
  const yearToYearChangeInput = (evt) => {
    const { name, value, type } = evt.target;
    let v = value;
    if (type === "number") {
      v = value.replace(/\D/g, "");
    }
    setYearToYearFilter((prev) => ({
      ...prev,
      [name]: v,
    }));
  };
  const containerStyles = {
    position: "relative",
    minHeight: "10vh",
  };
  const filterBtnStyles = {
    position: "absolute",
    top: "0",
    left: "0",
    display: "flex",
    zindex: 1000,
  };
  const timelineStyles = {
    overflowX: "auto",
    whiteSpace: "nowrap",
  };

  const toggleVisibility = (type) => {
    switch (type) {
      case "period": {
        setPeriodSellFilter((prev) => ({
          ...prev,
          IsVisible: !prev.IsVisible,
        }));
        if (!periodSales || !periodSales.length) getPeriodSalesData();
        break;
      }
      case "yearToYear": {
        setYearToYearFilter((prev) => ({
          ...prev,
          IsVisible: !prev.IsVisible,
        }));
        if (!yearToYear || !yearToYear.length) getYearToYearSalesData();
        break;
      }
      default:
        break;
    }
  };
  const handleSearchClick = (evt) => {
    const name = evt.currentTarget.closest("div").getAttribute("name");

    const terminalsActivityCaseTypes = new Map([
      ["lastHourTerminalsCount", 1],
      ["upTo72HoursTerminalsCount", 2],
      ["inactiveTerminalsCount", 3],
    ]);

    setShowModal(terminalsActivityCaseTypes.get(name));
  };
  const getDataForMachinesStat = (setState) => {
      // if (!showModal) return [];
      return fetchMssqlApi(
        "stat/filtered-machines-stat",
        {
          method: "POST",
          data: { filterType: showModal },
          hideNotification: true,
        },
        response => {
          console.log(response)
          const data = response || [];
          const d = data.map(({ MachineSerialNo, data }) => {
            const machineFullName = machinesList.find(
              ({ SerialNo }) => SerialNo === MachineSerialNo
            );
      
            if (!machineFullName) {
              console.error(
                `Machine with SerialNo ${MachineSerialNo} not found in machinesList`
              );
              return {
                machineFullName: `Unknown Machine (${MachineSerialNo})`,
                dataset: data,
              };
            }
      
            return {
              machineFullName: `${machineFullName.Name} (${machineFullName.SerialNo})`,
              dataset: data,
            };
          });
          console.log(d)
          setState(d)
          return d
        }
      );

  };


  const statIsEnable =
    useContext(NavigationContext)?.state?.permissions?.findIndex(
      ({ Name }) => Name === "VD_STAT"
    ) !== -1;

  return statIsEnable ? (
    <div>
      <div>
        <div className="container-fluid" style={{ minWidth: 0 }}>
          {/* <BestAndWorstMachineAndProducts
            bestMachines={machineAndProductsGeneratorFunction("bestMachines")}
            bestProducts={machineAndProductsGeneratorFunction("bestProducts")}
            worstMachines={machineAndProductsGeneratorFunction("worstMachines")}
            worstProducts={machineAndProductsGeneratorFunction("worstProducts")}
          /> */}
          <div className="row" name="sales-chart">
            <div className="col-12 col-md-12 col-lg-8">
              <div style={containerStyles}>
                <div style={filterBtnStyles}>
                  <ValueSalesChartFilter
                    salesChartFilter={sellDataFilter}
                    handleChangePaymentType={handleValueSalesChartFilterChange}
                    handleSubmit={getComparingSalesData}
                    machines={machinesList}
                    handleChangeMachine={handleChangeMachine}
                  />
                </div>
                {sellData && (
                  <div>
                    <ValueSalesChart
                      data={sellData}
                      secondBarKey={sellData[0]?.PrevDate}
                      firstBarKey={
                        sellData[
                          sellData.findIndex((i) =>
                            Object.keys(i).includes("CurrentDate")
                          )
                        ]?.CurrentDate
                      }
                      dataset2={sellData.map((i) => i?.PrevSummaryValue || 0)}
                      dataset1={sellData.map((i, idx) => {
                        if (!Object.keys(i).length && idx + 1 < moment().hour())
                          return 0;
                        if (idx + 1 > moment().hour()) return null;
                        if (
                          i.PrevHour < moment().hour() &&
                          !i.CurrentSummaryValue
                        )
                          return 0;
                        if (i.PrevHour === moment().hour()) return null;
                        if (i.PrevHour > moment().hour()) return null;
                        return i.CurrentSummaryValue;
                      })}
                      dataset3={sellData.map((i) =>
                        i.PrevHour === moment().hour()
                          ? (+i.CurrentSummaryValue || 0)
                          : null
                      )}
                      countDataset2={sellData.map(
                        (i) => +i?.PrevSummaryCount || 0
                      )}
                      countDataset1={sellData.map(
                        (i) => +i?.CurrentSummaryCount || 0
                      )}
                      countDataset3={(() => {
                        let a = sellData.map((i, idx) =>
                          i.PrevHour === moment().hour()
                            ? +i.CurrentSummaryCount || (idx === moment().hour()
                              ? 0
                              : null)
                            : null
                        );
                        if (!a.length) {
                          a = Array.from({ length: 24 }, (_, idx) =>
                            idx === moment().hour() ? 0 : null
                          );
                        }
                        return a;
                      })()}
                      handleChangePaymentType={
                        handleValueSalesChartFilterChange
                      }
                    />
                  </div>
                )}
              </div>
            </div>
            {terminalsActivity && (
              <TerminalsInfo
                handleSearchClick={handleSearchClick}
                lastHourTerminalsCount={
                  filterTerminalsCount(terminalsActivity).lastHourTerminalsCount
                }
                upTo72HoursTerminalsCount={
                  filterTerminalsCount(terminalsActivity)
                    .upTo72HoursTerminalsCount
                }
                inactiveTerminalsCount={
                  filterTerminalsCount(terminalsActivity).inactiveTerminalsCount
                }
                handleClick={handleClickTerminalButton}
              />
            )}
          </div>
          <div className="row timeline" style={timelineStyles}>
            {/* Timeline content here */}
          </div>
          {/* <div className="row" name="period-sales-chart">
            <div className="col-12">
              <div style={containerStyles}>
                <div style={filterBtnStyles}>
                  <PeriodBarChartFilter
                    periodSellFilter={periodSellFilter}
                    handleChangeInput={handlePeriodSalesChartFilterChange}
                    toggleVisibility={() => toggleVisibility("period")}
                    handleSubmit={getPeriodSalesData}
                    machines={machinesList}
                  />
                </div>
                {periodSales &&
                // periodSales.length &&
                periodSellFilter.IsVisible ? (
                  <PeriodBar
                    data={
                      periodSales &&
                      periodSales.map(({ SummaryValue }) => +SummaryValue)
                    }
                    count={
                      periodSales &&
                      periodSales.map(({ SummaryCount }) => +SummaryCount)
                    }
                    // labels={periodSales.map(({ datetime }) =>
                    //   moment(datetime).format("YYYY-MM-DD")
                    // )}
                    labels={(function () {
                      // const maxDayInMonth = Math.max(
                      //   moment(`${periodSellFilter.toCreateDateTime}`, "YYYY-MM").add(1, "M").daysInMonth(),
                      //   moment(`${periodSellFilter.toCreateDateTime}`, "YYYY-MM").daysInMonth()
                      // );
                      const maxDayInMonth = moment(
                        `${periodSellFilter.toCreateDateTime}`,
                        "YYYY-MM"
                      ).daysInMonth();
                      return Array.from(
                        { length: maxDayInMonth },
                        (_, day) => `${day < 9 ? "0" : ""}${day + 1}`
                      );
                    })()}
                    periodSellDataFilter={periodSellFilter}
                    setPeriodSellDataFilter={setPeriodSellFilter}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="row" name="year-to-year-chart">
            <div className="col-12">
              <div style={containerStyles}>
                <div style={filterBtnStyles}>
                  <YearToYearBarFilter
                    yearToYearFilter={yearToYearFilter || defaultFilters}
                    handleChangePaymentType={yearToYearChangeInput}
                    toggleVisibility={() => toggleVisibility("yearToYear")}
                    handleSubmit={getYearToYearSalesData}
                    handleChangeInput={yearToYearChangeInput}
                    machines={machinesList}
                  />
                </div>
                {yearToYear && yearToYearFilter.IsVisible ? (
                  <YearToYearBar
                    dataset1={
                      yearToYear &&
                      yearToYear.map(
                        ({ PrevSummaryValue }) => +PrevSummaryValue
                      )
                    }
                    dataset2={
                      yearToYear &&
                      yearToYear.map(
                        ({ CurrentSummaryValue }) => +CurrentSummaryValue
                      )
                    }
                    datasetCount1={
                      yearToYear &&
                      yearToYear.map(
                        ({ PrevSummaryCount }) => +PrevSummaryCount
                      )
                    }
                    datasetCount2={
                      yearToYear &&
                      yearToYear.map(
                        ({ CurrentSummaryCount }) => +CurrentSummaryCount
                      )
                    }
                    labels={
                      yearToYear &&
                      yearToYear.map(({ PrevMonth }) => months[--PrevMonth])
                    }
                    firstBarKey={`${yearToYear[0]?.PrevYear}`}
                    secondBarKey={`${yearToYear[0]?.CurrentYear}`}
                    handleChangeInput={yearToYearChangeInput}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {showModal &&
        <FilteredMachineSales
          header={(() => {
            switch (showModal) {
              case 1:
                return "";
              case 2:
                return "Brak transakcji od ponad 24h";
              case 3:
                return "Brak transakcji od ponad 72h";
            }
          })()}
          onHide={hideModal}
          showModal={Boolean(showModal)}
          machines={machinesList}
          filterType={showModal}
        />
      }
    </div>
  ) : (
    <></>
  );
}
