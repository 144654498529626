import React, { useState, useEffect, Fragment, useContext } from 'react'
import { LangContext } from '../../../context/lang-context'
import useForm from '../../../hooks/form-hook'
import FormSkel from '../../Modals/FormSkel'
import useFetch from '../../../hooks/fetchMSSQL-hook'


export default function Tags ({filter, setFilter, resetPage, enableMachineTags}) {
    const { TRL_Pack } = useContext(LangContext)
    const { fetchMssqlApi } = useFetch()


    const [tags, setTags] = useState([])
    const getTags = () => {
        localStorage.getItem("clientId") !== "console" && fetchMssqlApi('tags', {}, tags =>
            setTags(tags.machine.filter(tag => tag.options.length > 0))
        )
    }

    const toggleTag = tagId => () => {
        resetPage()

        if (filter.activeTags.includes(tagId))
            setFilter(prev => ({
                ...prev,
                activeTags: prev.activeTags.filter(tag => tag !== tagId)
            }))
        else setFilter(prev => ({ ...prev, activeTags: prev.activeTags.concat(tagId) }))
    }

    const { form, openForm, closeForm } = useForm()

    const [activeLabel, setActiveLabel] = useState(null)

    const handleChangeLabel = labelIdx => () => {

        if (labelIdx === activeLabel) {
            setActiveLabel(null)
        } else if (tags[labelIdx].options.length > 0) {
            setActiveLabel(labelIdx)
        } else {
            toggleTag(tags[labelIdx].tagId)()
            setActiveLabel(null)
        }
    }

    useEffect(() => getTags(), [])
    return (
        tags && enableMachineTags && (
            <div className='d-flex flex-column justify-content-center' >
                <h6 className="text-center mb-0">Tagi</h6>
                <div className="">
                    <div className="mb-n1 mt-1 d-flex flex-column justify-content-center flex-row">
                        {tags.map(tag =>
                            tag.options
                                .filter(opt => filter.activeTags.includes(opt.tagId))
                                .map(opt => (
                                    <button className="btn btn-info badge badge-pill px-2 py-1 mx-2 mb-1">
                                        {tag.label ? `${tag.label} - ${opt.name}` : opt.name}
                                    </button>
                                ))
                        )}
                        <button
                            className="btn btn-info badge badge-pill px-2 py-1 mx-2 mb-1"
                            onClick={openForm()}
                        >
                            <i className="fas fa-bars" />
                        </button>
                    </div>
                    {form && (
                        <FormSkel
                            headerText="Tagi"
                            noFooter
                            handleClose={closeForm}
                            classes="d-flex p-0"
                        >
                            <div className="w-50 overflow-auto" style={{ maxHeight: 250 }}>
                                {tags.map((tag, idx) => (
                                    <div
                                        key={idx}
                                        className={`font-weight-bolder list-group-item cursor-pointer ${idx === activeLabel ? 'active' : ''
                                        }`}
                                        onClick={handleChangeLabel(idx)}
                                    >
                                        {tag.others ? 'Inne' : tag.label}
                                    </div>
                                ))}
                            </div>
                            {activeLabel !== null && (
                                <div className="w-50 overflow-auto" style={{ maxHeight: 250 }}>
                                    {tags[activeLabel].options.map((opt, idx) => (
                                        <div
                                            key={idx}
                                            className={`font-weight-bolder list-group-item cursor-pointer ${filter.activeTags.includes(opt.tagId)
                                                ? 'list-group-item-success'
                                                : ''
                                            }`}
                                            onClick={toggleTag(opt.tagId)}
                                        >
                                            {opt.name}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </FormSkel>
                    )}
                </div>
            </div>
        )
    )
}