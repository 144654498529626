import moment from "moment";
const validateDateTime = (dateTime) => {
    const maxDate = moment("9999-12-31");
    const minDate = moment("1000-01-01");
    if (
        !moment(dateTime).isValid() ||
        moment(dateTime).isAfter(maxDate) ||
        moment(dateTime).isBefore(minDate)
    ) {
        return false;
    }
    return true;
};

export default validateDateTime;
