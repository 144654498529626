import axios from 'axios'
import { NotificationManager } from 'react-notifications'
import { API_URL, WKF_API_BASE_URL } from '../../../../config/config'


export default function getFiscalDoc ({NrUnik, FiscalDocId, Hash}, SetBtnState) {
    const defaultQuerySettings = {process: 1, timeout: 1, lastStatus: 0, readyToFile: false}
    SetBtnState({Report: 1, Id: FiscalDocId})
    axios({
        method: "POST",
        url: `${WKF_API_BASE_URL}/api/wkf/${Hash}/${localStorage.getItem("clientId")}/${NrUnik}`,
        responseType:'json',
        data: {
            ...defaultQuerySettings, 
            nrUnik: NrUnik
        }
    })
    .then(res => {
        if (res.status < 400) {
            
            if (res.status === 206) {
                axios({
                    method: "POST",
                    url: `${WKF_API_BASE_URL}/api/wkf/${Hash}/${localStorage.getItem("clientId")}/${NrUnik}`,
                    responseType: 'arraybuffer',
                    headers:{
                        "Content-Type":"application/pdf",
                    },
                    data: {
                        ...defaultQuerySettings, 
                        nrUnik: NrUnik,
                        readyToFile: true
                    }
                })
                .then((res) => {
                    SetBtnState({Report: null, Id: null})
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${Hash}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(() => {
                    NotificationManager.error("Nie udało się pobrać dokumentu")
                    SetBtnState({Report: null, Id: null})
                })
            } else {
                SetBtnState({Report: null, Id: null})
                window.open(`/wkf/params?nrunik=${NrUnik}&fiscaldoc=${Hash}&client=${localStorage.getItem('clientId')}`, "_blank")
            }
        } else {
            NotificationManager.error("Can`t find file")
            SetBtnState({Report: null, Id: null})
        }
    }) 
    .catch(() => {
        NotificationManager.error("Nie udało się pobrać dokumentu")
        SetBtnState({Report: null, Id: null})
    })
}