import React, { useState } from "react";

const ChartFilter = ({
  children,
  showBtn= true,
  text= "Odśwież",
  btnColor= "primary",
  searchFunction= () => {},
  showFilter = true,
  toggleVisibility = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const styles = {
    tooltipContainer: {
      position: "relative",
      display: "inline-block",
    },
    tooltipButton: {
      padding: "10px 20px",
      fontSize: "16px",
      cursor: "pointer",
      color: isOpen && "#0140BE",
    },
    tooltipWindow: {
      position: "absolute",
      top: "0",
      left: "100%",
      marginLeft: "10px",
      padding: "10px",
      backgroundColor: "#ffffff",
      border: "1px solid #cccccc",
      borderRadius: "4px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      zIndex: "1000",
      // width: "150px",
      minWidth: "20vw",
    },
  };

  const toggleTooltip = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={styles.tooltipContainer}>
      <i
        onClick={toggleTooltip}
        style={styles.tooltipButton}
        className="btn btn-group-toggle fas fa-filter"
      />
      {isOpen && (
        <div style={styles.tooltipWindow}>
          {/* <div
          className={`w-100 d-flex align-items-centere justify-content-between mb-2`}
          onClick={() => {
            toggleVisibility();
          }}
        >
          <div>{!showFilter ? "Pokaż" : "Ukryj"}</div>
          <div>
            <i
              className={`fas fa-toggle-${
                !showFilter
                  ? `off text-secondary `
                  : `on text-primary`
              } fa-lg`}
            />
          </div>
        </div> */}
          {
            showFilter &&
            children
          }
          <footer className="d-flex justify-content-between">
            {showBtn && (
              <button
                className={`btn mt-2 btn-${btnColor}`}
                onClick={() => {
                  searchFunction()
                  toggleTooltip()
                }}
              >
                {text}
              </button>
            )}
            <button
                className={`btn mt-2 btn-danger`}
                onClick={() => {
                  toggleTooltip()
                }}
              >
                Zamknij
              </button>
          </footer>
        </div>
      )}
    </div>
  );
};

export default ChartFilter;
