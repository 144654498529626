
import Chart from 'chart.js/auto';
export const PLNFormatterPlugin = {
    id: 'plnFormatter',
    beforeInit(chart) {
      const plnFormatter = new Intl.NumberFormat('pl-PL', {
        style: 'currency',
        currency: 'PLN',
      });
  
      if (chart.options.plugins.tooltip) {
        chart.options.plugins.tooltip.callbacks = {
          label: function (tooltipItem) {
            return plnFormatter.format(tooltipItem.raw);
          },
        };
      }
  
      chart.options.scales.y = {
        ...chart.options.scales.y,
        ticks: {
          callback: function (value) {
            return plnFormatter.format(value);
          },
        },
      };
    },
  };
  

Chart.register(PLNFormatterPlugin);




// TODO: Implement function to enable all plugins