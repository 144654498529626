

import React, {useEffect, useState} from 'react'
import {DB_TYPE} from "../../env"
// import { API_URL } from "../config/config"
// import LangProvider from '../context/lang-context'
// import ErrorProvider from '../context/error-context'
// import LoaderProvider from '../context/loader-context'
// import NotificationProvider from '../context/notification-context'
import NavigationProvider from '../context/navigation-context'
import SearchbarProvider from '../context/searchbar-context'
import {Redirect, Route, Switch} from 'react-router-dom'
import {NotificationContainer} from 'react-notifications'


import '../assets/bootstrap.min.css'
import './bootstrap.scss'
import 'react-notifications/lib/notifications.css'
import '../assets/fontawesome/css/all.css'

import './App.css'
// import axios from "axios"
import Login from '../views/Auth/login'
import Logout from '../views/Auth/Logout'
// import Products from '../views/Product/Products'
// import Categories from '../views/Category/Categories'
// import Settings from '../views/Settings/Settings'
import Config from '../views/Config/Config'
import Supply from '../views/Supply/Supply'
import Monitoring from '../views/ConsoleView/Monitoring/Monitoring'
import MonitoringMachine from '../views/ConsoleView/Monitoring/MonitoringMachine'
import MachinesConsole from '../views/ConsoleView/Machines/Machines'
import FullMachineConsole from '../views/ConsoleView/Machines/FullMachine'
import Terminals from '../views/ConsoleView/Terminals/Terminals'
import Trxs from '../views/ConsoleView/Terminals/Trxs'
import Clients from '../views/ConsoleView/Clients/Clients'
// import Tasks from '../views/ConsoleView/Tasks/Tasks'
import Products from '../views/ConsoleView/Products/Products'
import Categories from '../views/ConsoleView/Categories/Categories'
import Software from '../components/Software/Software';
import Machines from '../views/ClientView/Machines/Machines'
import FullMachine from '../views/ClientView/Machines/FullMachine'
// import Definition from '../views/ClientView/Definitions/Definition'
import Catalog from '../views/ClientView/Catalog/Catalog'
import Reports from '../views/ClientView/Reports/Reports'
import Tags from '../views/ClientView/Tags/Tags'
import Summaries from "../views/ClientView/Summaries/Summaries"
import useAuth from '../hooks/auth-hook'
import SummaryFilter from '../views/ClientView/Summaries/SummaryFilter'
import SummariesReport from "../views/ClientView/Summaries/SummariesReport"
import Codes from '../views/ClientView/Definitions/Codes';
import Personalise from '../views/ClientView/Personalise/Personalise'
import MachineLocations from '../components/Machine/MachineLocations'
import UserView from '../views/Users/UserView'
import Users from '../views/Users/Users'
import Product from '../views/ConsoleView/Products/Product'
import Recipe from '../views/ConsoleView/Products/Recipe'


import useHttp from '../hooks/use-http';
import PAS from '../views/ClientView/PAS'
import WIS from '../views/ClientView/WIS'
import Start from '../views/ClientView/Start'
import KPS from '../views/ClientView/KPS'
import INMS from '../views/ClientView/INMS'
import DOS from '../views/ClientView/DOS'
import ROS from '../views/ClientView/ROS'
import KOS from '../views/ClientView/KOS'
import INS from '../views/ClientView/INS'
import WZS from '../views/ClientView/WZS'
import PZS from '../views/ClientView/PZS'
import STS from '../views/ClientView/STS'
import ZWS from '../views/ClientView/ZWS'
import FZS from '../views/ClientView/FZS'
import FZ from '../views/ClientView/FZ'
import Groups from '../views/ClientView/Groups/Groups'
import PA from '../views/ClientView/PA'
import LastVI from '../views/ClientView/LastVI'
import RO from '../views/ClientView/RO'
import ErrorPage from '../views/ClientView/ErrorPage'
import DO from '../views/ClientView/DO'
import MMS from '../views/ClientView/MMS'
import MM from '../views/ClientView/MM'
import Routes from '../views/ClientView/Routes'
import Warehouses from '../views/ClientView/Warehouses'
import Bags from '../views/ClientView/Bags'
import MachineTypes from '../views/ClientView/Definitions/MachineTypes'
import Contractors from '../views/ClientView/Contractors'
import Contractor from '../views/ClientView/Contractor'
import Events from '../views/ClientView/Events'
import PZ from '../views/ClientView/PZ'
import ZW from '../views/ClientView/ZW'
import ST from '../views/ClientView/ST'
import WZ from '../views/ClientView/WZ'
import IN from '../views/ClientView/IN'
import WHIDoc from '../views/ClientView/WarehouseInventoryRelatedDoc'
import Promotions from '../views/ClientView/Promotions'
import PromotionBasket from '../views/ClientView/PromotionBasket'
import INM from '../views/ClientView/INM'
import KO from '../views/ClientView/KO'
import Prepaids from '../views/ConsoleView/prepaids/Prepaids'
import PrepaidCard from '../views/ConsoleView/prepaids/PrepaidCard'
import PrepaidGroup from '../views/ConsoleView/prepaids/PrepaidGroup'
import CashRegisters from '../views/ClientView/CashRegister/CashRegisters'
import CashRegisterOperation from '../views/ClientView/CashRegister/CashRegisterOperation'
import CashRegister from '../views/ClientView/CashRegister/CashRegister'
import PAUnlogin from '../views/ClientView/CashRegister/PAUnlogin'
import {useParams} from 'react-router-dom/cjs/react-router-dom.min'
import FiscalDocGetting from '../views/ClientView/CashRegister/FiscalDocGetting'
import axios from 'axios'
import CorrectionReason from '../views/ClientView/CorrectionReason'
import Errors from '../views/ClientView/Errors'
import EventsMachine from '../views/Events/Events'
import moment from 'moment'
import checkExaparedInHook from "../util/checkExaparedIn";
// import Sales from '../components/Dashboard/Sales'

const App = () => {
    axios.defaults.withCredentials = true
    const {isAuth, login, logout, setIsAuth} = useAuth()
    const params = useParams()
    const [permission, setPermission] = useState([])
    const {sendRequest} = useHttp();
    const { checkExaparedIn } = checkExaparedInHook();
    setInterval(() => {
        if (sessionStorage.getItem('DB_TYPE') === "mysql") return;

        if (isAuth) {
            if (!(() => {
                const clientId = localStorage.getItem('clientId')
                const expirationDate = localStorage.getItem('expirationDate')
                if (!clientId || !expirationDate) {
                    return false
                }
                if (new Date(expirationDate) <= new Date()) {
                    localStorage.removeItem('clientId')
                    localStorage.removeItem('expirationDate')
                    return false
                }

                const exparedIn = new Date(localStorage.getItem("expirationDate"))
                const now = new Date()
                const remainingTime = (exparedIn - now) / 60000
                if (remainingTime < 0 && moment(localStorage.getItem("expirationDate")).isValid()) {
                    logout()
                    return
                }
                checkExaparedIn()
                return true
            })()) {
                setIsAuth(false)
                moment(localStorage.getItem("expirationDate")).isValid() && logout()
                    .then(() => {
                    })
            }
        }
    }, 1000 * 60)
    /**
     * @Adding new routes by step:
     * @step1 add into routes if/else construction new route
     * @step2 add into switch/case construction in navigationContext (sidebar menu)
     * @step3 add into switch/case construction in permissions
     * @step4 add into switch/case construction in uspGetRolePermission
     */
    sessionStorage.setItem("DB_TYPE", DB_TYPE)
    let authRoutes = []

    if (sessionStorage.getItem('DB_TYPE') === "mysql") // MACHINE Routes
    {
        permission.forEach((permission) => {
            switch (permission.Name) {
                case "VD_MACHINE_CONFIG":
                    authRoutes.push({path: '/config', component: Config})
                    break;
                case "VD_PRODUCTS":
                    authRoutes.push({path: ['/products', '/products/:categoryId'], component: Products})
                    break;
                case "VD_CATEGORIES":
                    authRoutes.push({path: '/categories', component: Categories},)
                    break;
                case "VD_MACHINE_RECHARGE":
                    authRoutes.push({path: '/supply', component: Supply})
                    break;
                case "VD_EVENTS":
                    authRoutes.push({path: '/events', component: EventsMachine})
                    break;
                case "VD_USERS":
                    authRoutes.push({path: '/users', component: Users})
                    break;
                default:
                    break;
            }
        })
    } else if (localStorage.getItem('clientId') === 'console') {
        authRoutes.push({path: '/start', component: Start})
        permission.forEach((permission) => {
                switch (permission.Name) {
                    case "VD_MACHINES":
                        authRoutes.push({path: '/machines', component: MachinesConsole}, {
                            path: '/machine/:machineId',
                            component: FullMachineConsole
                        })
                        break;
                    case "VD_TERMINALS":
                        authRoutes.push({path: '/terminals', component: Terminals},)
                        break;
                    case "VD_TRXS":
                        authRoutes.push({path: '/trxs', component: Trxs},)
                        break;
                    case "VD_MONITORING":
                        authRoutes.push(
                            {path: '/monitoring', component: Monitoring},
                            {path: '/monitoring/:id', component: MonitoringMachine}
                        )
                        break;
                    case "VD_CUSTOMERS":
                        authRoutes.push({path: '/clients', component: Clients},)
                        break;
                    case "VD_PRODUCTS":
                        authRoutes.push({path: ['/products', '/products/:categoryId'], component: Products},)
                        break;
                    case "VD_CATEGORIES":
                        authRoutes.push({path: '/categories', component: Categories})
                        break;
                    case "VD_SOFTWARE":
                        authRoutes.push({path: '/software', component: Software})
                        break;
                    case "VD_PREPAID":
                        authRoutes.push(
                            {path: '/prepaids/:type', component: Prepaids},
                            {path: '/prepaids', component: Prepaids},
                            {path: '/prepaids/card/:id', component: PrepaidCard},
                            {path: '/prepaids/group/:id', component: PrepaidGroup}
                        )
                        break;
                    default:
                        break;
                }
            }
        )
    } else {
        authRoutes.push({path: '/start', component: Start})
        // authRoutes.push({ path: '/start/sales', component: Sales })
        permission.forEach((permission) => {
            switch (permission.Name) {
                case "VD_MACHINES":
                    authRoutes.push({path: '/machines', component: Machines}, {
                        path: '/machine/:machineId',
                        component: FullMachine
                    },)
                    break;
                // case "VD_START":
                //   authRoutes.push({ path: '/start', component: Start })
                //   break;
                case "VD_WIS":
                    authRoutes.push({path: '/wis', component: WIS},)
                    break;
                case "VD_KPS":
                    authRoutes.push({path: '/kps', component: KPS},)
                    break;
                case "VD_PAS":
                    authRoutes.push({path: '/pas', component: PAS}, {path: '/pa/:id', component: PA},)
                    break;
                case "VD_DOS":
                    authRoutes.push({path: '/dos', component: DOS}, {path: '/do/:id', component: DO})
                    break;
                case "VD_ROS":
                    authRoutes.push({path: '/ros', component: ROS}, {path: '/ro/:id', component: RO},)
                    break;
                case "VD_INMS":
                    authRoutes.push({path: '/inms', component: INMS}, {path: "/inm/:id", component: INM})
                    break;
                case "VD_INS":
                    authRoutes.push({path: '/ins', component: INS},
                        {path: '/in/:id', component: IN},
                        {path: '/na/:id', component: WHIDoc},
                        {path: '/br/:id', component: WHIDoc}
                    )
                    break;
                case "VD_WZS":
                    authRoutes.push({path: '/wzs', component: WZS}, {path: '/wz/:id', component: WZ})
                    break;
                case "VD_PZS":
                    authRoutes.push({path: '/pzs', component: PZS},)
                    authRoutes.push({path: '/pz/:id', component: PZ},)
                    break;
                case "VD_STS":
                    authRoutes.push({path: '/sts', component: STS}, {path: '/st/:id', component: ST})
                    break;
                case "VD_ZWS":
                    authRoutes.push({path: '/zws', component: ZWS}, {path: '/zw/:id', component: ZW})
                    break;
                case "VD_FZS":
                    authRoutes.push({path: '/fzs', component: FZS}, {path: '/fz/:warehouseOperationId', component: FZ})
                    break;
                case "VD_KOS":
                    authRoutes.push({path: '/kos', component: KOS}, {path: '/ko/:id', component: KO})
                    break;
                case "VD_PRODUCTS":
                    authRoutes.push({
                        path: ['/products', '/products/:categoryId'],
                        component: Products
                    }, {path: "/recipes", component: Products}, {
                        path: '/recipe/:id',
                        component: Recipe
                    }, {path: '/product/:ean', component: Product})
                    break;
                case "VD_CATEGORIES":
                    authRoutes.push({path: '/categories', component: Categories})
                    break;
                case "VD_PRODUCT_GROUPS":
                    authRoutes.push({path: '/groups', component: Groups})
                    break;
                case "VD_PRODUCT_CATALOG":
                    authRoutes.push({path: ['/catalog-products', '/catalog-categories'], component: Catalog})
                    break;
                case "VD_TAGS":
                    authRoutes.push({path: '/tags', component: Tags},)
                    break;
                // case "VD_REPORTS":
                //   authRoutes.push({ path: '/reports', component: Reports })
                //   break;
                case "VD_USERS":
                    authRoutes.push({path: '/users', component: UserView})
                    // authRoutes.push({ path: '/permissions', component:  })
                    authRoutes.push({path: '/users/:type', component: UserView})
                    break;
                case "VD_REPORT_CONDITIONS":
                    authRoutes.push({path: '/reports', component: Reports})
                    authRoutes.push({path: '/summaries', component: Summaries},
                        {path: '/summaries/:ReportId', component: SummaryFilter},
                        {path: '/summaries/:ReportId/:summariesReportId', component: SummariesReport})
                    break;
                case "VD_PAYMENT_CODES":
                    authRoutes.push({path: '/codes', component: Codes});
                    break;
                case "VD_PERSONALISE":
                    authRoutes.push({path: '/personalise', component: Personalise})
                    authRoutes.push({path: '/personalise/:type', component: Personalise})
                    break;
                case "VD_LOCATIONS":
                    authRoutes.push(
                        {path: '/machinelocations', component: MachineLocations},
                        {path: '/routes', component: Routes},
                    )
                    break;
                case "VD_TRXS":
                    authRoutes.push({path: '/trxs', component: Trxs})
                    break;
                case "VD_MACHINE_TYPES":
                    authRoutes.push({path: '/machinetypes', component: MachineTypes})
                    break;
                case "VD_TERMINALS":
                    authRoutes.push({path: '/terminals', component: Terminals},)
                    break;
                case "VD_LASTVI":
                    authRoutes.push({path: '/lastvi', component: LastVI},)
                    break;
                case "VD_MM":
                    authRoutes.push(
                        {path: '/mms', component: MMS},
                        {path: '/mm/:id', component: MM}
                    )
                    break;
                case "VD_WAREHOUSES":
                    authRoutes.push(
                        {path: '/whs', component: Warehouses},
                    )
                    break;
                case "VD_BAGS":
                    authRoutes.push(
                        {path: '/bags', component: Bags},
                    )
                    break;
                case "VD_CONTRACTORS":
                    authRoutes.push(
                        {path: '/contractors', component: Contractors},
                        {path: '/contractor/:id', component: Contractor},
                    )
                    break;
                case "VD_EVENTS":
                    authRoutes.push(
                        {path: '/events', component: Events},
                    )
                    break;
                case "VD_BASKETS":
                    authRoutes.push(
                        {path: '/promotions/:type', component: Promotions},
                        {path: '/promotions', component: Promotions},
                        {path: '/promotion/basket/:id', component: PromotionBasket},
                    )
                    break;
                case "VD_PREPAID":
                    authRoutes.push(
                        {path: '/prepaids/:type', component: Prepaids},
                        {path: '/prepaids', component: Prepaids},
                        {path: '/prepaids/card/:id', component: PrepaidCard},
                        {path: '/prepaids/group/:id', component: PrepaidGroup}
                    )
                    break;
                case "VD_MONITORING":
                    authRoutes.push(
                        {path: '/monitoring', component: Monitoring},
                        {path: '/monitoring/:id', component: MonitoringMachine}
                    )
                    break;
                case "VD_WKF":
                    authRoutes.push({path: "/wkfs", component: CashRegisters}
                        , {path: "/wkfs/:type", component: CashRegisters}
                        , {path: "/wkf/cashRegister/:id", component: CashRegister}
                        , {path: "/wkf/:type/:id", component: CashRegisterOperation}
                        , {path: "/wkf/:params", component: FiscalDocGetting}
                    )
                    break;
                case "VD_CR":
                    authRoutes.push({
                            path: "/corection-reasons", component: CorrectionReason
                        },
                    )
                case "VD_ERRORS":
                    authRoutes.push({
                            path: '/errors', component: Errors
                        },
                    )
                    break;
                default:
                    break;
            }
        })
        authRoutes.push({path: '/wkfs/configuration', component: CashRegisters})
    }
    const getInitialRoutes = () => {
        if (authRoutes.length > 0) {
            if (typeof (authRoutes[0].path) == "object")
                return <Redirect to={authRoutes[0].path[0]}/>

            else {
                return <Redirect to={authRoutes[0].path}/>
            }
        }
    }
    const url = new URL(document.URL)
    const pathname = url.pathname
    // const pathnames = pathname?.split('/')
    // const lastPastname = pathnames.at(-1)
    useEffect(() => {
        if (isAuth)
            // sendRequest({ url: 'customerIsConfigurated'} , res => {
            //   console.log(res)
            //   if (res.data.IsConfigurated) {
            sendRequest({url: 'permissions'}, permissions => setPermission(permissions.data));
        //   }
        // }
        // )
        // axios
        //   .get(`${API_URL}/api/permissions`, {
        //     headers: {
        //     },
        //   }).then(({ data }) => setPermission(data))

    }, [])
    return (
        <div className="d-flex min-vh-100 bg-light">
            {isAuth ? (
                <NavigationProvider>
                    <SearchbarProvider>
                        {authRoutes.length > 0 ? <Switch>

                                {authRoutes.map((route, idx) => (
                                    <Route key={idx} exact {...route} />
                                ))}
                                <Route
                                    exact
                                    path="/logout"
                                    render={() => (<Logout logout={logout}/>)}
                                />
                                {window.location.pathname === "/dashboard/login" && (sessionStorage.getItem('DB_TYPE') !== "mysql" && (
                                    <Redirect to='/start'/>))}
                                {sessionStorage.getItem('DB_TYPE') === "mysql" ? <Redirect to='/products'/> : null}
                                <Route exact path="/">
                                    <Start/>
                                </Route>
                                <Route exact path="*">
                                    <ErrorPage/>
                                </Route>
                                <Route path="*">
                                    <ErrorPage/>
                                </Route>
                                {getInitialRoutes()}

                            </Switch> :
                            null
                        }
                    </SearchbarProvider>
                </NavigationProvider>
            ) : (
                <Switch>
                    {
                        pathname.includes("wkf/") ? (
                                <>
                                    {/* http://localhost:4000/dashboard/wkf/params?doc=WBL2301002002_3246&clientId=dev */}
                                    <Route exact path={`/wkf/:params`} render={() => <PAUnlogin/>}/>
                                    {/* <Redirect to={`/wkf/${String(pathnames.at(-1)).toLowerCase()}`} /> */}
                                    {/* <Redirect to={`/wkf/${String(url.search)}`} /> */}
                                </>
                            )
                            :
                            (
                                <>
                                    <Route exact path="/login"
                                           render={() => (<Login login={login} setPermission={setPermission}/>)}/>
                                    <Redirect to="/login"/>
                                </>
                            )
                    }


                    <>
                        <Route exact path="/login"
                               render={() => (<Login login={login} setPermission={setPermission}/>)}/>
                        <Redirect to="/login"/>
                    </>
                </Switch>
            )}
            <NotificationContainer/>
        </div>
    )
}

export default App;