import axios from 'axios'
import { NotificationManager } from 'react-notifications'
import { API_URL, WKF_API_BASE_URL } from '../../../../config/config'


export default function getJWS ({NrUnik, FiscalDocId, Hash}, SetBtnState) {
    const defaultQuerySettings = {process: 1, timeout: 1, lastStatus: 0, readyToFile: false}
    SetBtnState({Report: 1, Id: FiscalDocId})
    axios({
        method: "POST",
        url: `${WKF_API_BASE_URL}/api/jws/${Hash}/${localStorage.getItem("clientId")}/${NrUnik}`,
        responseType:'text/plain',
        data: {
            ...defaultQuerySettings, 
            nrUnik: NrUnik
        }
    })
    .then(res => {
        SetBtnState({Report: null, Id: null})
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Hash}.txt`);
        document.body.appendChild(link);
        link.click();
    })
    .catch((err) => {
        console.error(err)
        NotificationManager.error(err.toString())
        SetBtnState({Report: null, Id: null})
    })
}