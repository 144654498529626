import moment from "moment";

export default async function validFilter({
  fromCreateDateTime,
  toCreateDateTime,
  fromYear,
  toYear,
  paymentType,
}, ...cmpFunctions) {
    switch (true) {
      // case(+fromYear > +moment().year() || +toYear > +moment().year()): 
      case(+fromYear > +toYear):
      case(+fromYear < 1970 || +toYear < 1970):
      case(+fromYear > 2099 || +toYear > 2099):
      case(fromCreateDateTime > moment().format("YYYY-MM-DD")):
      case(toCreateDateTime > moment().format("YYYY-MM-DD")):
      // case(fromCreateDateTime && fromCreateDateTime > toCreateDateTime):
      {
        throw "Incorrect year range";
      }
      case(paymentType > 2):
      throw "Incorrect payment type";
    }
    cmpFunctions.forEach(fnc => {
      if (!fnc()) throw "Incorrect filter"
    });
}
