import React from 'react'
import { useEffect, useState, } from 'react'
import axios from 'axios'
import {Spinner} from 'react-bootstrap'
import { API_URL, WKF_API_BASE_URL } from '../../../config/config'
import logo from '../../../assets/images/logo-retail24.png'
import { NotificationManager } from 'react-notifications'
import parseURLQuery from '../../../util/parseURLQuery'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export default function PAUnlogin() {
    
    // [pa, setPa] = useState({}),
    const 
        defaultQuerySettings = {process: 1, timeout: 1, lastStatus: 0, readyToFile: false},
        [querySettings, setQuerySettings] = useState(defaultQuerySettings),
        [text, setText] = useState(`Trwa pobieranie paragonu. <br/> Proszę czekać...`),
        [showButtons, setShowButtons] = useState(false),
        [initialPathname, setInitialPathname]=useState(document.location.pathname),
        paramsObj = parseURLQuery(window.location),
        history = useHistory(),
        [isDownloaded, setIsDownloaded] = useState(false)
        // let isMobile 
        // try {
        //     isMobile = window.navigator.userAgentData.mobile
        // } catch (e) {
        //     isMobile = true
        //     console.log(e)
        // }
    // console.log(paramsObj)
    
    // const getData = (receip) => {
    //     let binary = ''
    //     let bytes = new Uint8Array(receip)
    //     console.log(bytes)
    //     let length = bytes.byteLength
    //     for (let i = 0; i < length; i++) {
    //         binary += String.fromCharCode(bytes[i])
    //     }
    //     return window.btoa(binary)
    // }
    // const blobFormatterBase64 = base64b => {
    //     const byteArray = Uint8Array.from(
    //             atob(base64b)
    //             .split('')
    //             .map(char => char.charCodeAt(0))
    //         );
    //         return new Blob([byteArray], { type: 'application/pdf' })
    // }
    // const timeoutsMap = new Map(
    //     [
    //         [1, 1000],
    //         [2, 2000],
    //         [3, 3000],
    //         [4, 4000]
    //     ]
    // )
    const type = String(paramsObj.fiscaldoc).at(-1)
    useEffect(() => {
        setShowButtons && setQuerySettings(defaultQuerySettings)
    }, [setShowButtons])
    const getJWS = (data = querySettings) => {
        setShowButtons(false)
        axios({
            method: "POST",
            url: `${WKF_API_BASE_URL}/api/jws/${paramsObj.fiscaldoc}/${paramsObj.client}/${paramsObj.nrunik}`,
            responseType:'text/plain',
            data: {
                ...querySettings,
                client: paramsObj.client,
                nrUnik: paramsObj.nrunik,
            }
        })
        .then(res => {
            setShowButtons(null)
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${paramsObj.fiscaldoc}.txt`);
            document.body.appendChild(link);
            link.click();
        })
        .catch((err) => {
            setShowButtons(true)
            console.error(err)
            NotificationManager.error(err.toString())
        })
    }
    const getPdf = (data = querySettings) => {
        setShowButtons(false)
        // if (!paramsObj.fiscaldoc || !paramsObj.nrUnik || !paramsObj.client) history.replaceState("/login")

        axios({
            method: "post",
            url: `${WKF_API_BASE_URL}/api/wkf/${paramsObj.fiscaldoc}/${paramsObj.client}/${paramsObj.nrunik}`,
            responseType:'application/json',
            // headers:{"Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : null},
            data: {
                ...querySettings, 
                client: paramsObj.client,
                nrUnik: paramsObj.nrunik
            }
        })
        .then( answ => {
            setText(answ.data.message)
            delete answ.data.message
            if (answ.status === 206) {
                setQuerySettings(prev => ({...prev, readyToFile: true}))
                return true
            }
            else if (answ.status === 201) 
            {
                window.location.reload()
            }
            else if (answ.status < 300 && answ.status !== 206) {
                setQuerySettings({...answ.data, timeout: ++data.timeout})
            }
            if (data.timeout > 18) {
                setShowButtons(true)
                setText("Czas pobierania mineł <br/> Proszę spróbować ponownie")
                return null
            } 
            if (window.location.pathname !== initialPathname) return
            setTimeout(() => {
                getPdf()
            }, 5000);

        })
        .then(isReady => {
            if (isReady) {
                axios({
                    method: "post",
                    url: `${WKF_API_BASE_URL}/api/wkf/${paramsObj.fiscaldoc}/${paramsObj.client}/${paramsObj.nrunik}`,
                    responseType: 'arraybuffer',
                    headers:{
                        "Content-Type":"application/pdf",
                        "Authorization": `Bearer ${localStorage.getItem("token")}`
                    },
                    data: {
                        ...querySettings,
                        client: paramsObj.client,
                        nrUnik: paramsObj.nrunik,
                        readyToFile: true
                    }
                })
                // .then(res => {
                //     // const blobObj = new Blob([atob(res.data)], {type: "application/pdf"})
                //     const d = new Blob([res.data], {type: 'application/pdf'})
                //     const fileURL = window.URL.createObjectURL(d)
                //     window.open(fileURL, '_self')
                //     // window.URL.revokeObjectURL(fileURL)
                //     setShowButtons(null)
                // })
                // .then(res => {
                //     console.log(window.navigator)
                //     const d = new Blob([res.data], {type: 'application/pdf'})
                //     const fileURL = window.URL.createObjectURL(d)
                    
                //     if (isMobile) {
                //         window.open(fileURL, "_top")
                //     } else {
                //         setIsDownloaded(true)
                //         const iframe = document.createElement('iframe')
                //         iframe.src = fileURL
                //         iframe.width = "100%"  
                //         iframe.style.borderRadius = "10px"
                //         iframe.height = window.innerHeight - document.querySelector("figure").getBoundingClientRect().height - 70

                //         document.querySelector("section").appendChild(iframe);

                //     }
                //     setShowButtons(null)

                // })  
                .then(res => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${paramsObj.fiscaldoc}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    setShowButtons(null)
                })
                .catch(e => {
                    console.log(e)
                    setText( "Pobieranie nieudane")
                    setShowButtons(true)
                })  
            }
        })
        .catch(e => {
            console.log(e)
            setText(e?.response?.data?.message || "Coś poszło nie tak")
            setShowButtons(true)
        })
    }
    useEffect(() => {
        getPdf()
    }, [])
    useEffect(() => {
        showButtons === true && setQuerySettings(defaultQuerySettings)
        showButtons === null && setText("")
    }, [showButtons])
    return (
        <div className={"d-flex w-100 flex-column justify-content-between"} style={{minHeight: "100vh"}}>
            <section className='w-100 h-100 overflow-hidden' >
            <figure className='d-flex w-100 justify-content-center mt-3'>
                <div style={{maxWidth: (navigator.userAgentData.mobile && "100vw") || (!isDownloaded ? "35vw" : "12vw")}}>
                    <img
                        src={logo}
                        onError={evt => (evt.target.src = "")}
                        alt={"Retail24 logo"}
                        width="100%"
                        height="100%"
                    />
                </div>
            </figure>
            <div className='text-center'>
                <h4><div className='content' dangerouslySetInnerHTML={{__html: text}}></div></h4>
            </div>
                {showButtons === false && (
                    <div className="text-center h-100">
                        <Spinner
                            animation="grow"
                            size="xl"
                            role="status"
                            aria-hidden="true"
                            variant="primary"
                            className="b-0 pr-0 pl-0 "
                            style={{ height: '15vh', width: '15vh', marginTop: '10%' }}
                        />
                    </div>
                )
                    ||
                    showButtons === null && (
                        <div className="text-center" style={{ marginTop: '10%', bottom: 0 }}>
                            <div>
                                <h3>
                                    <div className="badge badge-success p-2">
                                        Zakończono pobieranie
                                    </div>
                                </h3>
                                {type == 1 && (
                                    <div >
                                    <button
                                        onClick={() => getJWS()}
                                        className="btn btn-lg btn-primary"
                                    >
                                            Pobierz JWS
                                    </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                }
            {/* </div> */}
        </section>
            <footer style={{
                "padding-bottom": "15px",
                "text-align": "center",
                marginBottom: "15%"
            }}>
                {
                    showButtons === null && (
                        <div>
                            <p className={'mb-0'}>
                                Plik jest dostępny w domyślnym folderze
                            </p>
                            <p>
                                pobierania plików przeglądarki
                            </p>
                        </div>
                    )

                }
                {



                    showButtons === true && (

                        <div className="w-100 text-center" style={{ marginTop: '10%', bottom: 0, marginBottom: '35%' }}>
                            <div>
                                <div>
                                    <button
                                        onClick={() => getPdf()}
                                        className="btn btn-lg btn-primary"
                                    >
                                        Spróbuj ponownie
                                    </button>
                                </div>
                                <div>
                                    <button
                                        onClick={() => {
                                            navigator.clipboard.writeText(document.URL)
                                            NotificationManager.success('Link skopiowany')
                                        }}
                                        className="btn btn-lg btn-secondary mt-4"
                                    >
                                        Skopiuj link
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                }
            </footer>
        </div>

    )
}
    