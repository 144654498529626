import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import useFetch from "../../../../../hooks/fetchMSSQL-hook";
import MachineRecordV3 from "./MachineRecordV3";
import "../plugins/CustomModalStyle.css";
import moment from "moment";

export default function FilteredMachineSalesV2({
  header,
  onHide,
  showModal,
  machines,
  filterType,
}) {
  const [data, setData] = useState(null);
  const { fetchMssqlApi } = useFetch();
  const getDataForMachinesStat = () => {
    return fetchMssqlApi(
      "stat/filtered-machines-stat",
      {
        method: "POST",
        data: { filterType: filterType },
        hideNotification: true,
      },
      (response) => {
        const data = response || [];
        const d = data.map(({ MachineSerialNo, data }) => {
          const machineFullName = machines.find(
            ({ SerialNo }) => SerialNo === MachineSerialNo
          );

          if (!machineFullName) {
            return {
              machineFullName: MachineSerialNo,
              dataset: data,
            };
          }

          return {
            machineFullName: `${machineFullName.Name} (${machineFullName.SerialNo})`,
            dataset: data,
          };
        });
        setData(d.sort((a, b) => a.machineFullName.localeCompare(b.machineFullName)));
        return d;
      }
    );
  };








  const dayOfWeek = [
    'Poniedziałek',
    'Wtorek',
    'Środa',
    'Czwartek',
    'Piątek',
    'Sobota',
    'Niedziela',
  ]

  useEffect(() => {
    getDataForMachinesStat();
    moment().locale("pl");
  }, []);

  return (
    data && (
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={onHide}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data && data.length ? (
            <div style={{ maxHeight: "75vh", overflowY: "auto" }}>
              <div
                style={{
                  overflowX: "auto",
                  maxWidth: "100%",
                  position: "relative",
                }}
              >
                <table
                  className="table"
                  style={{
                    width: "100%",
                    tableLayout: "auto",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        className="sticky-column"
                        style={{
                          position: "sticky",
                          left: 0,
                          background: "white",
                          zIndex: 2,
                          padding: 0,
                          borderTop: 0
                        }}
                      ></th>
                      {data[0].dataset.map((entry, index) => (
                        <th
                          key={index}
                          className="text-center"
                          style={{
                            borderRight: ".2px solid grey",
                            textAlign: "center",
                            minWidth: "50px",
                            borderTop: 0
                          }}
                        >
                          <div>{Object.keys(entry)[0]}</div>
                          <div>{dayOfWeek[moment(Object.keys(entry)[0]).weekday()]}</div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map(({ machineFullName, dataset }, index) => (
                      <MachineRecordV3
                        key={index}
                        machineFullName={machineFullName}
                        dataset={dataset}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Zamknij</Button>
        </Modal.Footer>
      </Modal>
    )
  );
}
