import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import JavascriptTimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import pl from 'javascript-time-ago/locale/pl'
import Dashboard from './dashboard_env/app/App'
import LangProvider from './dashboard_env/context/lang-context'
import LoaderProvider from './dashboard_env/context/loader-context'
import ErrorProvider from './dashboard_env/context/error-context'
import NotificationProvider from './dashboard_env/context/notification-context'
import * as serviceWorker from './serviceWorker'

import 'react-notifications/lib/notifications.css'
import { EndSessionTimerProvider } from './dashboard_env/context/end-session-timer-context'

JavascriptTimeAgo.addLocale(en)
JavascriptTimeAgo.addLocale(pl)
// if (!sessionStorage.getItem("isOnline")) sessionStorage.setItem("isOnline", navigator.onLine)
//
//
//     if(window.location.pathname === "/dashboard/login" && sessionStorage.getItem("isMobile")) {
//         sessionStorage.removeItem("isMobile")
//     }
// parseCookies()

// const environment = process.env.CLIENT_ENVIRONMENT || DB_TYPE
// if (process.env.NODE_ENV === 'development') console.log(`Environment: ${environment}`)

render(
    <BrowserRouter basename={'dashboard'}>
            <LangProvider>
                <LoaderProvider>
                    <ErrorProvider>
                        <NotificationProvider>
                            <EndSessionTimerProvider>
                                <Dashboard />
                            </EndSessionTimerProvider>
                        </NotificationProvider>
                    </ErrorProvider>
                </LoaderProvider>
            </LangProvider>
    </BrowserRouter>,
    document.getElementById('root')
)

serviceWorker.unregister()

// serviceWorker.register({
//     onUpdate: (registration) => {
//     sessionStorage.setItem("isUpdateAvailabvle", true)
//     console.log('New version available!');
//     if (registration.waiting) {
//         registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//     }
//     },
//     onSuccess: () => {
//         console.log('Content is cached for offline use.');
//     }
// })

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
