import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { LangContext } from '../../../context/lang-context'
import { NavigationContext } from '../../../context/navigation-context'
import useFetch from '../../../hooks/fetchMSSQL-hook'

import ReturnLink from '../../../components/Return/ReturnLink'

import MachineInfo from '../../../components/Machine/MachineInfo'
import MachineProducts from '../../../components/Machine/MachineProducts'
// import MachineProductsNew from '../../../components/Machine/MachineProductsNew'
import MachineRecipes from '../../../components/Machine/MachineRecipes'
import MachinesImages from '../../../components/Machine/MachinesImages'
import NewMachine from './NewMachine/NewMachine'
import MachineTask from './CommandCaller/MachineTask'
import MachineLogs from './MachineLogs'
// import MachineReports from '../../../components/Machine/MachineReports'

const FullMachine = () => {
    const { machineId } = useParams()
    const { fetchMssqlApi } = useFetch()
    const { setHeaderData } = useContext(NavigationContext)
    const { TRL_Pack } = useContext(LangContext)

    const [machineData, setMachineData] = useState(null)
    const [param, setParam] = useState(machineId)
    const getMachineItemsType = () => {
        param !== "0" &&
        fetchMssqlApi(`machine/${machineId}`, {}, machineData =>
            setMachineData({ ...machineData })
        )
    }
    const [type, setType] = useState(0)
    useEffect(() => {
        setHeaderData({ text: TRL_Pack.machine.header })
        getMachineItemsType()
    }, [])
    useEffect(() => {
        getMachineItemsType()
    }, [param])

    const checkMachineType = (machineType) => !!machineType.split(/[^A-Za-z0-10]/g).filter((el) =>
        ["V10", "V11", "V12", "V13", "V14"].includes(String(el).toUpperCase())
    ).length;
    return (
        machineId !== "0" ? (
                <>
                    <div>
                        <ul className="nav nav-tabs machine-tabs mb-3">
                            <li className="nav-item">
                                <button
                                    className={`nav-link btn ${type === 0 && 'active'}`}
                                    onClick={() => type !== 0 && setType(0)}
                                    tabIndex="0"
                                >
                                    Automat
                                </button>
                            </li>
                            {machineData && checkMachineType(machineData.MachineTypeName) && (
                                <>
                                    <li className="nav-item">
                                        <button
                                            className={`nav-link btn ${type === 1 && 'active'}`}
                                            onClick={() => type !== 1 && setType(1)}
                                            tabIndex="0"
                                        >
                                            Ustawienia R24 VMC
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button
                                            className={`nav-link btn ${type === 2 && 'active'}`}
                                            onClick={() => type !== 2 && setType(2)}
                                            tabIndex="0"
                                        >
                                            Ustawienia zaawansowane R24 VMC
                                        </button>
                                    </li>
                                </>
                            )}
                            <li className="nav-item">
                                <button
                                    className={`nav-link btn ${type === 3 && 'active'}`}
                                    onClick={() => type !== 3 && setType(3)}
                                    tabIndex="0"
                                >
                                    Historia stanu
                                </button>
                            </li>
                        </ul>
                    </div>
                    {type === 0 && (
                        <>
                            <div>
                                <ReturnLink path="/machines" />
                                {machineData && (
                                    <>
                                        <MachineInfo machineData={machineData} machineId={machineData.MachineId} updateMachine={setMachineData} data={machineData} />
                                        {machineData.MaintenanceType === 'SNACK' ? (
                                            <MachineProducts machineId={machineId} />
                                        ) : (
                                            machineData.MaintenanceType === 'COFFEE' && (
                                                <MachineRecipes machineId={machineId} />
                                            )
                                        )}
                                        {/* <div className="row">
                    <MachineReports serialNo={serialNo} />
                  </div> */}
                                    </>
                                )}
                            </div>
                        </>
                    )}
                    {type === 1 && (
                        machineData && (
                            <MachinesImages machineData={machineData}/>
                        )
                    )}
                    {type === 2 && (
                        machineData && (
                            <MachineTask
                                machineData={machineData}/>
                        )
                    )}
                    {type === 3 && (
                        machineData && (
                            <MachineLogs
                                machineData={machineData}/>
                        )
                    )}
                </>
            )
            : (
                <>
                    <NewMachine setParam={setParam}/>
                </>
            )
    )
}

export default FullMachine;