import React, { useContext, useEffect, useState } from 'react'
import { LangContext } from '../../context/lang-context'
import { NotificationContext } from '../../context/notification-context'
import { LoaderContext } from '../../context/loader-context'
import axios from 'axios'

import { NotificationManager } from 'react-notifications'
// import  from '../ClientView/';
import ReCAPTCHA from 'react-google-recaptcha'

import logo from '../../assets/images/logo-retail24.png'
import { API_URL, SHOP_URL } from '../../config/config'
import Loader from '../../components/Loader/Loader'
import moment from 'moment'
import useFetch from "../../hooks/fetchMSSQL-hook";
import plFlag from "../../assets/flags/pl.png"
import gbFlag from "../../assets/flags/gb.png"

const Login = ({ login, setPermission }) => {
    const { ErrorNotification } = useContext(NotificationContext)
    const { loader, incrementRequests, decrementRequests } = useContext(LoaderContext)
    const [token, setToken] = useState("")
    const { changeLanguage, TRL_Pack } = useContext(LangContext)
    const [isVerified, setIsVerified] = useState(false);
    const { fetchMssqlApi } = useFetch()
    const [blockedTime, setBlockedTime] = useState({
        start: null,
        end: null,
        duration: null
    })
    const [text, setText] = useState("")
    const [clientId, setClientId] = useState();

    useEffect(() => {
        // fetchMssqlApi('machines', {}, data => {
        //     setClientId(data[0].ClientId)
        // })
    }, [])

    const grecaptchaObject = window.grecaptcha
    const blockTicker = (end) => {
        const time = moment.unix(blockedTime.duration)
        // .diff(Date.now())
        if (end - Date.now() < 0) {
            console.log("interval cleared")
            setBlockedTime(prev => ({
                ...prev,
                start: null,
                end: null,
                duration: null
            }))
            setText("")
            return
        }
        setText(`liczba prób logowania została przekroczona, spróbuj ponownie za ${time}`)
    }


    const handleSubmit = evt => {
        evt.preventDefault()

        let { email, password, clientId } = evt.target.elements

        if (email.value && password.value) {
            incrementRequests()
            axios
                .put(`${API_URL}/api/auth/login`, {
                    Email: email.value.toLowerCase(),
                    Password: password.value,
                    ClientId: clientId.value.toLowerCase(),
                    token: token
                })
                .catch(e => {
                    const err = e.response
                    if (e.message === 'Network Error') {
                        setText(`Podczas logowania występił problem. Prosimy o zgłoszeniu do serwisu`)
                        setTimeout(() => {
                            setText("")
                        }, 20000);
                        return
                    }
                    if (err.status > 299 && err.status !== 429) {
                        NotificationManager.error(Object.values(e.response.data)[0])
                        throw e;
                    }
                    if (err.status === 429) {
                        const duration = err.data.message.blockDuration
                        const message = err.data.message.msg
                        if (Object.values(blockedTime).some(i => !i)) {
                            const d = moment.duration(duration)
                            setText(`Ilość prób logowania wyczerpana, sprobój ponownie za ${d.minutes() ? `${d.minutes()} minuty` : ""} ${d.seconds()} sekund`)
                            setTimeout(() => {
                                setText("")
                            }, duration);
                        }
                        NotificationManager.error(message)
                    }
                    return Promise.reject(e);
                })
                .then(res => {
                    localStorage.setItem("lastLogin", JSON.stringify({
                        email: email.value.toLowerCase(),
                        clientId: clientId.value.toLowerCase()
                    }))
                    decrementRequests()
                    setToken("")
                    if (sessionStorage.getItem('DB_TYPE') !== 'mysql') {
                        grecaptchaObject.reset()
                        if (res.headers["Origin"] === "http://localhost:3001" || res.headers["Origin"] === "http://localhost:3000" || res.headers["Access-Control-Allow-Origin"] === "https://portal.retail24.pl") {
                            axios.defaults.baseURL = res.headers["Origin"]
                        }
                        setIsVerified(true)
                    }
                    login(res.data.token, clientId.value.toLowerCase())
                    axios
                        .get(`${API_URL}/api/permissions`, {
                            headers: {},
                        }).then(({ data }) => setPermission(data))

                        .catch(err => {
                            decrementRequests()
                            console.log(err)
                        })
                })
                .catch(err => {
                    setIsVerified(false)
                    if(sessionStorage.getItem('DB_TYPE') !== 'mysql') {
                        grecaptchaObject.reset()
                    }
                    decrementRequests()
                })
        }
    }

    useEffect(() => {
        if (sessionStorage.getItem('DB_TYPE') === 'mysql') {
            const timeout = setTimeout(() => {
                window.location.replace(SHOP_URL);
            }, 40000); // Return to shop in local enviroment after 40 seconds

            return () => {
                clearTimeout(timeout);
            }; // Cleanup
        }
    }, []);


    const handleVerify = async (value) => {
        if (value) {
            setToken(value)
            setIsVerified(true)
            // Submit your form data here (make sure to handle server-side validation)
        }
    };
    return (
        <>
            {loader && <Loader />}
            <div className="col col-md-6 col-lg-5 mx-auto d-flex align-items-center"

            >
                <div className="container">
                    {sessionStorage.getItem('DB_TYPE') === "mysql" && (
                        <a href={SHOP_URL}>
                            Wróć
                        </a>)}
                    {text && (
                        <div className='w-100 d-flex align-items-center'
                             style={{ background: "#EFE282", height: "50px", paddingLeft: "5px" }}>
                            <b>{text}</b>
                        </div>
                    )}
                    <div className="card card-body bg-fade mb-4">
                        <div className="text-center mt-2 mb-3">
                            <img src={logo} alt="logo" height="80" />
                        </div>
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <div className="form-group">
                                <label className="small">{TRL_Pack.auth.email}</label>
                                <input
                                    type="email"
                                    name="email"
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label className="small">{TRL_Pack.auth.password}</label>
                                <input
                                    type="password"
                                    name="password"
                                    defaultValue=""
                                    autoComplete="off"
                                    className="form-control"
                                    required
                                    autoFocus
                                />
                            </div>
                            <div className="form-group">
                                <label className="small">{TRL_Pack.auth.clientId}</label>
                                <input
                                    type="text"
                                    name="clientId"
                                    className="form-control"
                                    defaultValue={(sessionStorage.getItem("DB_TYPE") === "mysql") ? clientId : ""}
                                    disabled={sessionStorage.getItem("DB_TYPE") === "mysql"}
                                    // defaultValue={
                                    //     (sessionStorage.getItem("DB_TYPE") === "mysql") ?
                                    //         ("multivend") :
                                    //         (localStorage.getItem('lastLogin') ? JSON.parse(localStorage.getItem('lastLogin')).clientId : "console")
                                    // }
                                    autoCapitalize="none"
                                    required
                                />
                            </div>
                            {sessionStorage.getItem("DB_TYPE") !== "mysql" ? (
                                <div className='mb-2 d-flex justify-content-center'>
                                    <ReCAPTCHA
                                        sitekey={"6LfIxKMpAAAAALIFN0aeEXcoxT0fUbQzq_3-0rjS"}
                                        onChange={handleVerify}
                                    />
                                </div>
                            ) : null}
                            <button className="btn btn-success btn-block"
                                    disabled={(sessionStorage.getItem("DB_TYPE") !== "mysql") ? !isVerified : false}>
                                {TRL_Pack.buttons.login}
                            </button>
                        </form>
                    </div>
                    {sessionStorage.getItem("DB_TYPE") === "mysql" && (
                        <div className="text-center">
                            <button
                                className="btn btn-link p-0 mx-3 rounded-circle"
                                onClick={changeLanguage('en')}
                            >
                                <img className="flag" src={gbFlag} alt="en_flag" />
                            </button>
                            <button
                                className="btn btn-link p-0 mx-3 rounded-circle"
                                onClick={changeLanguage('pl')}
                            >
                                <img className="flag" src={plFlag} alt="pl_flag" />
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
};

export default Login;
