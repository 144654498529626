import { NotificationManager } from "react-notifications"
import axios from 'axios'
import { API_URL } from "../config/config"
import { useContext, useEffect, useState } from "react"
import { LoaderContext } from "../context/loader-context"
import { EndSessionTimerContext } from '../context/end-session-timer-context'

export default () => {
    const [isInProgress, setIsInProgress] = useState(false)
    const {showloader} = useContext(LoaderContext)
    const {StartTimer, TimerIsShow, EndTimer} = useContext(EndSessionTimerContext)

    const getToken = () => axios.get(`${API_URL}/api/auth/retakeToken`, { method: 'GET', })

    function checkExaparedIn() {
        if (!isInProgress) {
            const expiredIn = new Date(localStorage.getItem("expirationDate"))
            let expiredInIsValid = !Number.isNaN(expiredIn.valueOf())
            const now = new Date()

            const remainingTime = (expiredIn - now) / 60000
            if (remainingTime < 0 && !showloader) return
            if (expiredInIsValid && remainingTime < 3 && !TimerIsShow)
                StartTimer((expiredIn - now))
            setIsInProgress(true)

            if (remainingTime < 4) return getToken().then(() => {
                const newExpirationDate = new Date(new Date().getTime() + 1000 * 60 * 60)
                localStorage.setItem('expirationDate', newExpirationDate.toISOString())
                EndTimer()
            })
                .catch((err) => {
                    console.error(err)
                }).finally(() => setIsInProgress(false))

        }
    }

    return {checkExaparedIn, isInProgress}
}