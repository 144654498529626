import React from "react";

export default function TerminalsInfo({
  lastHourTerminalsCount,
  upTo72HoursTerminalsCount,
  inactiveTerminalsCount,
  handleClick = () => {},
  handleSearchClick = () => {},
}) {
  return (
    <div className="col-12 col-md-12 col-lg-4 mt-3 justify-content-center">
      <div
        className="h6 w-100 d-flex justify-content-center"
        style={{ fontSize: ".8rem" }}
      >
        Terminale (transakcje)
      </div>
      <div className="row justify-content-around">
        <div className="card bg-success col-9 text-white mb-1 p-0">
          <label
            className="card-header d-flex w-100 m-0 justify-content-center align-items-center p-2"
            name={"lastHourTerminalsCount"}
            style={{ height: "6vh" }}
            onClick={handleClick}
          >
            <h3 className="m-0 p-0">{lastHourTerminalsCount}</h3>
          </label>
        </div>
        <div
          className="card bg-success col-2 text-white mb-1 d-flex justify-content-center align-items-center"
          onClick={handleSearchClick}
          name={"lastHourTerminalsCount"}
        >
          <i className="fas fa-search fa-lg"></i>
        </div>
      </div>
      <div className="row justify-content-around">
        <div className="card bg-warning col-9 text-white mb-1 p-0">
          <label
            className="card-header d-flex w-100 justify-content-center align-items-center p-2"
            style={{ height: "6vh", position: "relative" }}
            name={"upTo72HoursTerminalsCount"}
            onClick={handleClick}
          >
            <div className="w-100 d-flex justify-content-center">
              <h3 className="m-0 p-0">{upTo72HoursTerminalsCount}</h3>
            </div>
            <div
              className="w-100 d-flex justify-content-end pr-1"
              style={{ bottom: "0", position: "absolute", fontSize: ".8rem" }}
            >
              brak transakcji od ponad 24h
            </div>
          </label>
        </div>
        <div
          className="card bg-warning col-2 text-white mb-1 d-flex justify-content-center align-items-center"
          onClick={handleSearchClick}
          name={"upTo72HoursTerminalsCount"}
        >
          <i className="fas fa-search fa-lg"></i>
        </div>
      </div>
      <div className="row justify-content-around">
        <div className="card bg-danger col-9 text-white mb-1 p-0">
          <label
            onClick={handleClick}
            className="card-header d-flex w-100  justify-content-center align-items-center w-100 p-2"
            style={{ height: "6vh", position: "relative" }}
            name={"inactiveTerminalsCount"}
          >
            <div className="w-100 d-flex justify-content-center">
              <h3 className="m-0 p-0">{inactiveTerminalsCount}</h3>
            </div>
            <div
              className="w-100 d-flex justify-content-end pr-1"
              style={{ bottom: "0", position: "absolute", fontSize: ".8rem" }}
            >
              brak transakcji od ponad 72h
            </div>
          </label>
        </div>
        <div
          className="card bg-danger col-2 text-white mb-1 d-flex justify-content-center align-items-center"
          onClick={handleSearchClick}
          name={"inactiveTerminalsCount"}
        >
          <i className="fas fa-search fa-lg"></i>
        </div>
      </div>
    </div>
  );
}
